// // $icons-path: '~ag-grid-community/src/styles/material-icons/';
// // @import '~ag-grid-community/src/styles/ag-grid.scss';
// // @import '~ag-grid-community/src/styles/ag-theme-material.scss';

// //common colours
// //TODO: add clarksons npm package and consume palette from there
// // $clk-orange: #ed6f00;
// // $clk-blue: #0085b2;
// // $clk-pink: #ae2f5a;
// // $clk-red: #ff1744;
// // $clk-green: #00a65a;
// // $clk-grey: #87919b;
// // $clk-black: #000000;

// // $clk-pill-padding: 1px 8px 1px 8px;

// // $secondary-font-size: 13px;
// // $secondary-foreground-color: black;
// // $selected-color: #d1dfea;
// // $selected-color-text: black;

// // $fort-border-width: 1px;

// // $fort-border-color: #979797;
// // $fort-border: $fort-border-width solid $fort-border-color;

// // $primary-color: $clk-blue;
// // $in-app-modal-dialog-z-index: 1000;

$info-background-color: #0f446f;
$info-text-color: #ffffff;
$error-background-color: #ff1744;
$error-text-color: #ffffff;

// // $brand-header-background-color: #091624;
// // $brand-header-text-color: #b6babe;
$brand-header-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
// //   0 8px 8px 0 rgba(0, 0, 0, 0.24);

// // $grid-size: 5px;
// // $cell-horizontal-padding: $grid-size * 1.2;
// // $secondary-font-weight: 400;
// // $header-background-color: #f3f3f3;
// // $header-background-image: #f3f3f3;
// // // $input-height: 2px;
// // $icon-size: 12px;

// // $grid-row-fg-faded: rgba(0, 0, 0, 0.3);

// // $grid-row-clarksons-public-fg-color: rgb(255, 23, 68);
// // $grid-row-clarksons-public-fg-color-faded: rgba(255, 23, 68, 0.3);
// // $grid-row-clarksons-public-bg-color: #ffffff;

// // $grid-row-clarksons-private-fg-color: #ff1744;
// // $grid-row-clarksons-private-bg-color: #000000;

// // $grid-row-market-public-fg-color: #000000;
// // $grid-row-market-public-bg-color: #ffffff;

// // $grid-row-market-private-fg-color: #000000;
// // $grid-row-market-private-bg-color: #ff1744;

// // $grid-row-own-program-fg-color: rgb(0, 162, 216);
// // $grid-row-own-program-fg-color-faded: rgba(0, 162, 216, 0.3);
// // $grid-row-own-program-bg-color: rgb(255, 255, 255);

// // $grid-row-weight: 600;

// // $grid-group-row-clarksons-color: #000000;
// // $grid-group-row-clarksons-background-color: #d3d3d3;

// // $font-family-default: 'Roboto', 'Cera GR Light', sans-serif;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

#root,
div.light-background {
    width: 100%;
    height: 100%;
}

div.light-background {
    height: calc(100vh - 56px) !important;
}
.pinned--open div.light-background {
    height: calc(100vh - 93px) !important;
}

div.error-page {
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

div.error-page .details-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

div.error-page .warning-icon-container {
    border-radius: 150px;
    width: 150px;
    height: 150px;
    background-color: #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

div.error-page .warning-icon-container .warning-icon {
    width: 75px;
    height: 75px;
}

.ag-root {
    box-sizing: border-box;
    // border: 1px solid $border-color;
}

.flex-container {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
    align-content: stretch !important;
    align-items: flex-start !important;
}

#appContainer {
    width: 100%;
    height: calc(100vh - 56px) !important;
}

.pinned--open {
    #appContainer {
        height: calc(100vh - 93px) !important;
    }
}

.flex-layout-container {
    @extend .flex-container;
}

.flex-layout-container .flex-item {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}

.flex-layout-container .flex-item-fill {
    order: 0;
    flex: 1 1 100%;
    align-self: auto;
    height: 100%;
    display: flex;
    flex-flow: column;
}

#version-updated-message {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    text-align: center;
}

#version-updated-message > div {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
    height: 465px;
    width: 745px;
}

#version-updated-message > div > div.message-header {
    height: 150px;
    background-image: url('SEA FORT logo dark large.png');
    background-size: 170px 50px;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 50%;
}

#version-updated-message > div > div.info-message {
    background-color: #195d95;
    display: flex;
    align-items: center;
    min-height: 72px;
    padding: 0 8em;
}

#version-updated-message > div > div.info-message > span {
    font-size: 18px;
    font-weight: $font-weight-medium;
    width: 100%;
}

#version-updated-message > div > div.message-content {
    color: rgba(0, 0, 0, 0.54);
    font-size: 18px;
    font-weight: $font-weight-regular;
    padding: 2em 0;
}

#version-updated-message > div > button {
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    background-color: #2598f3;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 180px;
    margin: 0 auto 5em;
    font-size: 14px;
    font-weight: $font-weight-bold;
}

#version-updated-message > div > div.message-footer {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: $font-weight-medium;
    padding: 1em 0;
}

%message {
    width: 100%;
    padding: 0.5em;
    font-size: 0.95em;

    h1 {
        display: inline-block;
        min-width: 13em;
        margin-right: 1em;
        /*Reset browser defaults*/
        font-weight: normal;
        margin-bottom: 0;
        font-size: 1.15em;
    }
}

.notification-messages {
    z-index: 10;
    flex-shrink: 0; /*Prevents lack of expanding to show full contents while < max-height - https://stackoverflow.com/a/43666293 (probably needed because the contents are flex) */
    position: absolute;
    width: 100%;
    overflow-y: auto;
    max-height: 12em;
    box-shadow: $brand-header-box-shadow;
    background-color: inherit;

    .content {
        .notification-messages-item {
            &:not(:first-child) {
                margin-top: 0.3em;
            }
        }
    }
}

.error-message {
    @extend %message;

    background-color: $error-background-color;
    color: $error-text-color;
}

.info-message {
    @extend %message;

    background-color: $info-background-color;
    color: $info-text-color;
}

// // //common colours
// // //TODO: add clarksons npm package and consume palette from there
// // $clk-orange: #ed6f00;
// // $clk-blue: #0085b2;
// // $clk-pink: #ae2f5a;
// // $clk-red: #ff1744;
// // $clk-green: #00a65a;
// // $clk-grey: #87919b;
// // $clk-black: #000000;

// // $clk-pill-padding: 1px 8px 1px 8px;

// // $icons-path: '~ag-grid-community/src/styles/material-icons/';
// // $secondary-font-size: 13px;
// // $secondary-foreground-color: black;
// // $selected-color: #d1dfea;
// // $selected-color-text: black;

// // $fort-border-width: 1px;

// // $fort-border-color: #979797;
// // $fort-border: $fort-border-width solid $fort-border-color;

// // $primary-color: $clk-blue;
// // $in-app-modal-dialog-z-index: 1000;

// // $info-background-color: #0f446f;
// // $info-text-color: #ffffff;
// // $error-background-color: #ff1744;
// // $error-text-color: #ffffff;

// // $brand-header-background-color: #091624;
// // $brand-header-text-color: #b6babe;
// // $brand-header-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12),
// // 0 8px 8px 0 rgba(0, 0, 0, 0.24);

// // $grid-size: 5px;
// // $cell-horizontal-padding: $grid-size * 1.2;
// // $secondary-font-weight: 400;
// // $header-background-color: #f3f3f3;
// // $header-background-image: #f3f3f3;
// // // $input-height: 2px;
// // $icon-size: 12px;
// // @import '~ag-grid-community/src/styles/ag-grid.scss';
// // @import '~ag-grid-community/src/styles/ag-theme-material.scss';

// // $grid-row-fg-faded: rgba(0, 0, 0, 0.3);

// // $grid-row-clarksons-public-fg-color: rgb(255, 23, 68);
// // $grid-row-clarksons-public-fg-color-faded: rgba(255, 23, 68, 0.3);
// // $grid-row-clarksons-public-bg-color: #ffffff;

// // $grid-row-clarksons-private-fg-color: #ff1744;
// // $grid-row-clarksons-private-bg-color: #000000;

// // $grid-row-market-public-fg-color: #000000;
// // $grid-row-market-public-bg-color: #ffffff;

// // $grid-row-market-private-fg-color: #000000;
// // $grid-row-market-private-bg-color: #ff1744;

// // $grid-row-own-program-fg-color: rgb(0, 162, 216);
// // $grid-row-own-program-fg-color-faded: rgba(0, 162, 216, 0.3);
// // $grid-row-own-program-bg-color: rgb(255, 255, 255);

// // $grid-row-weight: 600;

// // $grid-group-row-clarksons-color: #000000;
// // $grid-group-row-clarksons-background-color: #d3d3d3;

// // $font-family-default: 'Roboto',
// // 'Cera GR Light',
// // sans-serif;
// // $font-weight-regular: 400;
// // $font-weight-medium: 500;
// // $font-weight-bold: 700;

// // .ag-row {
// //   font-weight: $grid-row-weight;
// // }

// // .ag-menu {
// //   max-height: unset;
// // }

// // .ag-row-selected,
// // .ag-rich-select-row-selected {
// //   color: $selected-color-text;
// // }

// // .ag-cell.errored {
// //   border-color: $clk-red;
// //   background-image: svg('src/components/icons/WarningIconRed.svg');

// //   &.grid-row-market-private,
// //   &.grid-cell-private {
// //     background-image: svg('src/components/icons/WarningIconWhite.svg');
// //     border-color: white;
// //   }

// //   border-width: 1px;
// //   border-style: solid;
// //   background-size: 18px 16px;
// //   background-repeat: no-repeat;
// //   background-position: right 0.2em center;
// // }

// // .ag-theme-material {
// //   .ag-cell-inline-editing {
// //     height: $row-height - 2;
// //     padding: 0;

// //     input {
// //       box-sizing: border-box;
// //       line-height: $row-height - 2 !important;
// //       height: $row-height - 2 !important;
// //       padding: 0 !important;
// //       border-bottom-width: 0px !important;
// //     }
// //   }

// //   .ag-theme-material .ag-tab-header .ag-tab.ag-tab-selected .ag-icon-filter {
// //     background: none;
// //   }

// //   .ag-filter {
// //     .ag-set-filter-list {
// //       height: initial;
// //       max-height: $virtual-item-height * 12;
// //     }
// //   }

// //   .ag-virtual-list-viewport {
// //     max-height: $virtual-item-height * 12;
// //   }

// //   .ag-header-cell-label {
// //     width: calc(100% - 35px);
// //   }

// //   .ag-icon-menu {
// //     background-image: svg('src/components/icons/empty_filter.svg');
// //     background-size: 18px;
// //     width: 35px;
// //   }

// //   .ag-header-cell.ag-header-cell-filtered .ag-icon-menu:hover,
// //   .ag-header-cell.ag-header-cell-filtered:hover .ag-icon-menu:hover {
// //     background-image: svg('src/components/icons/filter_active.svg');
// //   }

// //   .ag-icon-menu:hover {
// //     background-image: svg('src/components/icons/empty_filter_active.svg');
// //   }

// //   .ag-icon-filter,
// //   .ag-header-cell.ag-header-cell-filtered:hover .ag-icon-menu {
// //     background-image: svg('src/components/icons/filter.svg');
// //     background-color: $header-background-color;
// //     background-size: 18px;
// //     position: absolute;
// //     right: 6px;
// //     width: 35px;
// //   }

// //   .ag-header-cell.ag-header-cell-filtered:hover .ag-icon-filter {
// //     display: none;
// //     z-index: -1;
// //   }
// // }

// // div.condensed-view .ag-row {
// //   font-size: 9px !important;
// // }

// // div.condensed-view .ag-row.grid-group-row-clarksons {
// //   line-height: 20px !important;
// // }

// // div.condensed-view .ag-cell,
// // div.condensed-view .ag-header-cell {
// //   padding-top: 0 !important;
// //   line-height: 20px !important;
// // }

// // div.condensed-view span.ag-icon.ag-icon-checkbox-unchecked,
// // div.condensed-view span.ag-icon.ag-icon-checkbox-checked {
// //   background-size: 12px 12px !important;
// //   height: 12px !important;
// //   width: 12px !important;
// //   top: 2px !important;
// //   left: 10px !important;
// // }

// // div.condensed-view span.ag-header-icon,
// // div.condensed-view span.ag-header-icon.ag-header-cell-menu-button {
// //   margin-top: 4px !important;

// //   span.ag-icon {
// //     background-size: 12px 12px !important;
// //     height: 12px !important;
// //     width: 12px !important;
// //   }
// // }

// // .ag-header {
// //   background-color: $header-background-color;
// // }

// // .ag-header-cell-text {
// //   opacity: 0.7;
// // }

// // // stolen from balham theme to have column separators
// // .ag-header-cell::after,
// // .ag-header-group-cell::after {
// //   border-right: 1px dotted darken($fort-border-color, 1);
// //   content: ' ';
// //   height: $header-height - $grid-size;
// //   margin-top: $grid-size * 0.5;
// //   position: absolute;
// //   right: 0;
// //   text-indent: -2000px;
// //   top: 0;
// //   width: 2px;
// // }

// // .ag-row {
// //   font-weight: $grid-row-weight;
// // }

// // .ag-row-selected,
// // .ag-rich-select-row-selected {
// //   color: $selected-color-text;
// // }

// // .ag-cell.errored {
// //   border-color: $clk-red;
// //   background-image: svg('src/components/icons/WarningIconRed.svg');
// //   &.grid-row-market-private,
// //   &.grid-cell-private {
// //     background-image: svg('src/components/icons/WarningIconWhite.svg');
// //     border-color: white;
// //   }

// //   border-width: 1px;
// //   border-style: solid;
// //   background-size: 18px 16px;
// //   background-repeat: no-repeat;
// //   background-position: right 0.2em center;
// // }

// // .ag-theme-material {
// //   .ag-cell-inline-editing {
// //     // height: $row-height - 2;
// //     padding: 0;
// //     input {
// //       box-sizing: border-box;
// //       // line-height: $row-height - 2 !important;
// //       // height: $row-height - 2 !important;
// //       padding: 0 !important;
// //       border-bottom-width: 0px !important;
// //     }
// //   }

// //   .ag-theme-material .ag-tab-header .ag-tab.ag-tab-selected .ag-icon-filter {
// //     background: none;
// //   }

// //   .ag-filter {
// //     .ag-set-filter-list {
// //       height: initial;
// //       // max-height: $virtual-item-height * 12;
// //     }
// //   }

// //   .ag-virtual-list-viewport {
// //     // max-height: $virtual-item-height * 12;
// //   }

// //   .ag-header-cell-label {
// //     width: calc(100% - 35px);
// //   }

// //   .ag-icon-menu {
// //     background-image: svg('src/components/icons/empty_filter.svg');
// //     background-size: 18px;
// //     width: 35px;
// //   }

// //   .ag-header-cell.ag-header-cell-filtered .ag-icon-menu:hover,
// //   .ag-header-cell.ag-header-cell-filtered:hover .ag-icon-menu:hover {
// //     background-image: svg('src/components/icons/filter_active.svg');
// //   }

// //   .ag-icon-menu:hover {
// //     background-image: svg('src/components/icons/empty_filter_active.svg');
// //   }

// //   .ag-icon-filter,
// //   .ag-header-cell.ag-header-cell-filtered:hover .ag-icon-menu {
// //     background-image: svg('src/components/icons/filter.svg');
// //     background-color: $header-background-color;
// //     background-size: 18px;
// //     position: absolute;
// //     right: 6px;
// //     width: 35px;
// //   }

// //   .ag-header-cell.ag-header-cell-filtered:hover .ag-icon-filter {
// //     display: none;
// //     z-index: -1;
// //   }
// // }

// // div.condensed-view .ag-row {
// //   font-size: 9px !important;
// // }

// // div.condensed-view .ag-row.grid-group-row-clarksons {
// //   line-height: 20px !important;
// // }

// // div.condensed-view .ag-cell,
// // div.condensed-view .ag-header-cell {
// //   padding-top: 0 !important;
// //   line-height: 20px !important;
// // }

// // div.condensed-view span.ag-icon.ag-icon-checkbox-unchecked,
// // div.condensed-view span.ag-icon.ag-icon-checkbox-checked {
// //   background-size: 12px 12px !important;
// //   height: 12px !important;
// //   width: 12px !important;
// //   top: 2px !important;
// //   left: 10px !important;
// // }

// // div.condensed-view span.ag-header-icon,
// // div.condensed-view span.ag-header-icon.ag-header-cell-menu-button {
// //   margin-top: 4px !important;

// //   span.ag-icon {
// //     background-size: 12px 12px !important;
// //     height: 12px !important;
// //     width: 12px !important;
// //   }
// // }

// // .ag-header {
// //   background-color: $header-background-color;
// // }

// // .ag-header-cell-text {
// //   opacity: 0.7;
// // }

// // stolen from balham theme to have column separators
// // .ag-header-cell::after,
// // .ag-header-group-cell::after {
// //   border-right: 1px dotted darken($fort-border-color, 1);
// //   content: ' ';
// //   // height: $header-height - $grid-size;
// //   margin-top: $grid-size * 0.5;
// //   position: absolute;
// //   right: 0;
// //   text-indent: -2000px;
// //   top: 0;
// //   width: 2px;
// // }
// // .ag-root {
// //   box-sizing: border-box;
// //   // border: 1px solid $border-color;
// // }

// // / Adjust color for grid row background usage by a percentage lighter or darker
// // @function set-row-bkg-adj-color($color, $adjustment) {
// //   @if (lightness($color) > 50) {
// //     @return darken($color, $adjustment);
// //   } @else {
// //     @return lighten($color, $adjustment);
// //   }
// // }
// // / Adjust color for grid row background usage by a percentage lighter or darker
// // @function set-row-bkg-adj-color($color, $adjustment) {
// //   @if (lightness($color) > 50) {
// //     @return darken($color, $adjustment);
// //   }

// //   @else {
// //     @return lighten($color, $adjustment);
// //   }
// // }

// // / Mixin for using a background color in an AG Grid row
// // / It automatically adjusts the color for hover/selected behaviours to retain
// // / contextual color changes with user interactions on the row
// // / NOTE: Grid styles need to be very specific, any overlapping styles will cause Excel to attempt to merge the styles.
// // / Excel appears to have a problem merging styles which results in unintended consequences that can cause considerable confusion.
// // @mixin grid-row-bkg($color) {
// //   background: $color;
// //   @if (lightness($color) < 50) {
// //     .ag-icon {
// //       filter: invert(100%);
// //     }
// //   }
// //   &.ag-row-hover {
// //     background: set-row-bkg-adj-color($color, 5%);
// //   }
// //   &.ag-row-selected {
// //     background: set-row-bkg-adj-color($color, 15%);
// //   }
// // }
// // @mixin grid-pill($background-color: inherit, $color: white) {
// //   padding: $clk-pill-padding;
// //   border-radius: 4px;
// //   background-color: $background-color;
// //   color: $color;
// //   font-weight: 400;
// //   line-height: 14px;
// //   text-align: center;
// //   height: 14px;
// // }

// // .grid-row-clarksons-private,
// // .ag-row-hover.grid-row-clarksons-private,
// // .grid-cell-private-clarksons-public,
// // .ag-row-hover.grid-cell-private-clarksons-public {
// //   color: $grid-row-clarksons-private-fg-color;
// //   background-color: $grid-row-clarksons-private-bg-color;
// // }

// // .grid-row-market-public {
// //   color: $grid-row-market-public-fg-color;
// // }

// // .grid-row-market-private {
// //   color: $grid-row-clarksons-public-fg-color;
// //   background-color: $grid-row-clarksons-public-bg-color;
// // }

// // .grid-row-clarksons-public {
// //   color: $grid-row-clarksons-public-fg-color;
// // }

// // .grid-row-reported-clarksons-public {
// //   color: $grid-row-clarksons-public-fg-color-faded;
// // }
// // .grid-row-market-private,
// // .ag-row-hover.grid-row-market-private,
// // .grid-row-reported-market-private,
// // .ag-row-hover.grid-row-reported-market-private {
// //   color: $grid-row-market-private-fg-color;
// //   background-color: $grid-row-market-private-bg-color;
// // }

// // .grid-row-faded-out {
// //   color: $grid-row-fg-faded !important;
// // }

// // div.grid-row-deleted {
// //   text-decoration: line-through;
// //   & div.ag-cell {
// //     text-decoration: line-through;
// //   }
// // }

// // .grid-row-own-program {
// //   color: $grid-row-own-program-fg-color !important;
// // }

// // .grid-row-reported-own-program {
// //   color: $grid-row-own-program-fg-color-faded !important;
// // }

// // .grid-cell-pill-orange {
// //   @include grid-pill($clk-orange);
// // }
// // .grid-cell-pill-grey {
// //   @include grid-pill($clk-grey);
// // }
// // .grid-cell-pill-green {
// //   @include grid-pill($clk-green);
// // }
// // .grid-cell-pill-red {
// //   @include grid-pill($clk-pink);
// // }
// // .grid-cell-pill-blank {
// //   padding: $clk-pill-padding;
// // }
// // .grid-cell-pill-black {
// //   @include grid-pill($clk-black);
// // }

// // .grid-group-row-clarksons {
// //   color: $grid-group-row-clarksons-color;
// //   border: $fort-border !important;
// //   font-weight: bolder;
// //   // line-height: $row-height;
// //   @include grid-row-bkg($grid-group-row-clarksons-background-color);
// //   .ag-group-expanded,
// //   .ag-group-contracted {
// //     margin-left: 15px;
// //     .ag-icon {
// //       margin-right: 12px !important;
// //     }
// //   }
// // }
// // .grid-cell-private {
// //   color: $grid-row-market-private-fg-color;
// //   background-color: $grid-row-market-private-bg-color;
// // }
// // .ag-row-focus {
// //   @include grid-row-bkg($selected-color);
// // }
// // div.suppress-focus:focus {
// //   outline: none;
// // }
// // div.live-updates-diconnected-banner {
// //   background-color: $clk-red;
// // }
// // %message {
// //   width: 100%;
// //   padding: 0.5em;
// //   font-size: 0.95em;
// //   font-family: $font-family-default;

// //   h1 {
// //     display: inline-block;
// //     min-width: 13em;
// //     margin-right: 1em;
// //     /*Reset browser defaults*/
// //     font-weight: normal;
// //     margin-bottom: 0;
// //     font-size: 1.15em;
// //   }

// //   section {
// //     display: block;

// //     &:not(:first-child) {
// //       margin-top: 0.5em;
// //     }
// //   }
// // }

// // #version-updated-message {
// //   font-family: $font-family-default;
// //   position: fixed;
// //   width: 100%;
// //   height: 100%;
// //   top: 0;
// //   left: 0;
// //   right: 0;
// //   bottom: 0;
// //   background-color: rgba(0, 0, 0, 0.3);
// //   z-index: 2;
// //   cursor: pointer;
// //   text-align: center;
// // }

// // #version-updated-message > div {
// //   position: absolute;
// //   top: 50%;
// //   left: 50%;
// //   background-color: #fff;
// //   transform: translate(-50%, -50%);
// //   height: 465px;
// //   width: 745px;
// // }

// // #version-updated-message > div > div.message-header {
// //   height: 150px;
// //   background-image: url('SEA FORT logo dark large.png');
// //   background-size: 170px 50px;
// //   background-repeat: no-repeat;
// //   background-position-y: 50%;
// //   background-position-x: 50%;
// // }

// // #version-updated-message > div > div.info-message {
// //   background-color: #195d95;
// //   display: flex;
// //   align-items: center;
// //   min-height: 72px;
// //   padding: 0 8em;
// // }

// // #version-updated-message > div > div.info-message > span {
// //   font-size: 18px;
// //   font-weight: $font-weight-medium;
// //   width: 100%;
// // }

// // #version-updated-message > div > div.message-content {
// //   color: rgba(0, 0, 0, 0.54);
// //   font-size: 18px;
// //   font-weight: $font-weight-regular;
// //   padding: 2em 0;
// // }

// // #version-updated-message > div > button {
// //   border-radius: 5px;
// //   -webkit-box-shadow: none;
// //   box-shadow: none;
// //   border: none;
// //   background-color: #2598f3;
// //   color: #ffffff;
// //   padding: 10px;
// //   cursor: pointer;
// //   text-transform: uppercase;
// //   outline: none;
// //   display: -ms-flexbox;
// //   display: flex;
// //   -ms-flex-align: center;
// //   align-items: center;
// //   -ms-flex-pack: center;
// //   justify-content: center;
// //   width: 180px;
// //   margin: 0 auto 5em;
// //   font-size: 14px;
// //   font-weight: $font-weight-bold;
// // }

// // #version-updated-message > div > div.message-footer {
// //   color: rgba(0, 0, 0, 0.5);
// //   font-size: 14px;
// //   font-weight: $font-weight-medium;
// //   padding: 1em 0;
// // }

// // .notification-messages {
// //   flex-shrink: 0; /*Prevents lack of expanding to show full contents while < max-height - https://stackoverflow.com/a/43666293 (probably needed because the contents are flex) */

// //   .content {
// //     position: absolute;
// //     width: 100%;
// //     overflow-y: auto;
// //     max-height: 12em;
// //     box-shadow: $brand-header-box-shadow;
// //     background-color: inherit;

// //     .notification-messages-item {
// //       &:not(:first-child) {
// //         margin-top: 0.3em;
// //       }
// //     }
// //   }
// // }

// // .ag-popup-editor {
// //   z-index: 1001 !important; /* Ag-grid library's default z-index is 1, which is enough to hover over vanilla content, but we need more, so that cell editing never gets obstructed by the navigation menus etc. `!important` becase the library puts its '1'  in a more specific selector due to usage of a theme-specific class. */
// // }

// // .brand-header-part {
// //   background-color: $brand-header-background-color;

// //   &,
// //   & * {
// //     &::-webkit-scrollbar-thumb {
// //       &,
// //       &:window-inactive {
// //         background-color: $brand-header-text-color;
// //       }
// //     }
// //   }
// // }

// // .transition-fade-500in-200out-enter {
// //   opacity: 0.01;
// // }

// // .transition-fade-500in-200out-enter-active {
// //   opacity: 1;
// //   transition: opacity 500ms ease-in;
// // }

// // .transition-fade-500in-200out-exit {
// //   opacity: 1;
// // }

// // .transition-fade-500in-200out-exit-active {
// //   opacity: 0.01;
// //   transition: opacity 200ms ease-in;
// // }

// // .ui.dimmer {
// //   /*Generic modal dialog overlay.*/
// //   z-index: $in-app-modal-dialog-z-index;
// // }

// // .ui.menu,
// // .brand-header-part {
// //   z-index: $in-app-modal-dialog-z-index + 1; /*Keep the brand header above generic dialogs. Let user still read app notifications or navigate out of the app just like he can use browser navigation bar. */
// // }

// // .linkButton {
// //   cursor: pointer;
// //   color: $clk-blue;
// // }

// // .linkButton:hover {
// //   text-decoration: underline;
// // }

// // // The filter input's height is set to 15px by the ag-material theme, which causes the filter input boxes to get squashed.
// // input.ag-filter-filter {
// //   height: auto !important;
// // }

// // #clearButton {
// //   cursor: pointer;
// // }

// // .MuiTypography-body1 {
// //   font-size: inherit !important;
// // }

// // .fort-grid-tooltip {
// //   font-family: 'Roboto', 'Cera GR Light', sans-serif;
// //   opacity: 1 !important;
// //   background-color: $header-background-color !important;
// //   border-color: $fort-border-color !important;
// //   th {
// //     text-align: right;
// //     color: $clk-grey;
// //     font-weight: normal;
// //   }
// //   td {
// //     color: $clk-black;
// //   }
// // }
// // .data-size-selector {
// //   width: 200px;
// //   padding-left: 10px;
// //   padding-top: 10px;
// // }

// // div.ag-root span.ag-icon.ag-icon-checkbox-checked {
// //   background: url(components/icons/Checkbox_active.svg) no-repeat;
// //   background-size: 20px 20px;
// //   height: 20px;
// //   left: 5px;
// //   position: relative;
// //   top: 2.5px;
// //   width: 20px;
// // }

// // div.ag-root span.ag-icon.ag-icon-checkbox-unchecked {
// //   background: url(components/icons/Checkbox_empty.svg) no-repeat;
// //   background-size: 20px 20px;
// //   height: 20px;
// //   left: 5px;
// //   position: relative;
// //   top: 2.5px;
// //   width: 20px;
// // }

// // div.ag-root span.ag-icon.ag-icon-checkbox-indeterminate {
// //   background: url(components/icons/Checkbox_indeterminate.svg) no-repeat;
// //   background-size: 20px 20px;
// //   height: 20px;
// //   left: 5px;
// //   position: relative;
// //   top: 2.5px;
// //   width: 20px;
// // }

// // #export-options {
// //   font-family: $font-family-default;
// //   position: fixed;
// //   width: 100%;
// //   height: 100%;
// //   top: 0;
// //   left: 0;
// //   right: 0;
// //   bottom: 0;
// //   background-color: rgba(0, 0, 0, 0.3);
// //   z-index: 2;
// // }

// // #export-options > div {
// //   position: absolute;
// //   top: 50%;
// //   left: 50%;
// //   background: url(components/images/Background.png) 0 0px no-repeat;
// //   transform: translate(-50%, -50%);
// //   height: 464px;
// //   width: 418px;
// // }

// // #export-options > div > div.export-options-header {
// //   margin: 40px 0 0 15px;
// //   text-align: left;
// //   height: 60px;
// // }

// // #export-options > div > div.export-options-header > h3 {
// //   font-weight: 700;
// //   font-size: 17px;
// //   display: inline;
// //   margin-left: 25px;
// // }

// // #export-options > div > div.export-options-header > div#export-options-close {
// //   /*background: url(components/icons/close.svg) 0 0px no-repeat;*/
// //   cursor: pointer;
// //   font-weight: $font-weight-bold;
// //   font-size: large;
// //   height: 14px;
// //   width: 14px;
// //   margin: 13px 48px 0 0;
// //   float: right;
// // }

// // #export-options > div > div.export-options-content {
// //   font-size: $secondary-font-size;
// //   height: 260px;
// //   text-align: center;
// // }

// // #export-options
// //   > div
// //   > div.export-options-content
// //   > div.export-options-options {
// //   height: 120px;
// //   padding-top: 30px;
// // }

// // #export-options
// //   > div
// //   > div.export-options-content
// //   > div.export-options-options
// //   > div.export-options-option {
// //   height: 90px;
// //   width: 90px;
// //   float: left;
// //   font-family: $font-family-default;
// // }

// // #export-options
// //   > div
// //   > div.export-options-content
// //   > div.export-options-options
// //   > div.export-options-option:first-child {
// //   margin: 0 40px 0 100px;
// // }

// // #export-options
// //   > div
// //   > div.export-options-content
// //   > div.export-options-options
// //   > div.export-options-option
// //   > input[type='checkbox'] {
// //   display: none;
// // }

// // #export-options
// //   > div
// //   > div.export-options-content
// //   > div.export-options-options
// //   > div.export-options-option
// //   > input[type='checkbox']
// //   + label {
// //   cursor: pointer;
// //   height: 100px;
// //   width: 100px;
// //   display: inline-block;
// //   padding: 0 0 0 0px;
// // }

// // #export-options
// //   > div
// //   > div.export-options-content
// //   > div.export-options-options
// //   > div.export-options-option
// //   > input[type='checkbox']:checked
// //   + label {
// //   height: 100px;
// //   width: 100px;
// //   display: inline-block;
// //   padding: 0 0 0 0px;
// // }

// // #export-options
// //   > div
// //   > div.export-options-content
// //   > div.export-options-options
// //   > div.export-options-option
// //   > input[type='checkbox']
// //   + label.email {
// //   background: url(components/icons/email_unselected.svg) 0 0px no-repeat;
// // }

// // #export-options
// //   > div
// //   > div.export-options-content
// //   > div.export-options-options
// //   > div.export-options-option
// //   > input[type='checkbox']:checked
// //   + label.email {
// //   background: url(components/icons/email_selected.svg) 0 0px no-repeat;
// // }

// // #export-options
// //   > div
// //   > div.export-options-content
// //   > div.export-options-options
// //   > div.export-options-option
// //   > input[type='checkbox']
// //   + label.excel {
// //   background: url(components/icons/excel_unselected.svg) 0 0px no-repeat;
// // }

// // #export-options
// //   > div
// //   > div.export-options-content
// //   > div.export-options-options
// //   > div.export-options-option
// //   > input[type='checkbox']:checked
// //   + label.excel {
// //   background: url(components/icons/excel_selected.svg) 0 0px no-repeat;
// // }

// // #export-options
// //   > div
// //   > div.export-options-content
// //   > div.export-options-options
// //   > div.ui.input {
// //   width: 225px;
// // }

// // #export-options > div > div.export-options-footer {
// //   height: 100px;
// //   padding: 35px 0 0 0;
// // }

// // #export-options > div > div.export-options-footer > div {
// //   float: right;
// // }

// // #export-options
// //   > div
// //   > div.export-options-footer
// //   > div
// //   > a[name='close-button'] {
// //   font-weight: $font-weight-bold;
// //   cursor: pointer;
// //   float: left;
// //   margin: 10px 25px;
// //   height: 25px;
// //   width: 50px;
// // }

// // #export-options > div > div.export-options-footer > div > button {
// //   margin-right: 25px;
// // }

// // div.menu-button > i.activity-icon {
// //   background-image: svg('src/components/icons/Activity_icon.svg');
// //   height: 19px;
// //   width: 23px;
// //   margin-right: 5px;
// // }

// // div.menu-button:hover > i.activity-icon {
// //   background-image: svg('src/components/icons/Activity_active_icon.svg');
// // }

// // div#activity.activity-compact
// //   > div
// //   > div#activity-menu
// //   > div.mainmenu
// //   > div.menu-toggle-format {
// //   background-image: svg('src/components/icons/Expand_icon.svg');
// //   background-repeat: no-repeat;
// //   height: 14px;
// //   width: 14px;
// // }

// // div#activity.activity-expanded
// //   > div
// //   > div#activity-menu
// //   > div.mainmenu
// //   > div.menu-toggle-format {
// //   background-image: svg('src/components/icons/Retract_icon.svg');
// //   background-repeat: no-repeat;
// //   height: 14px;
// //   width: 14px;
// // }

// // div#activity.activity-compact
// //   > div
// //   > div#activity-menu
// //   > div.mainmenu
// //   > div.menu-slide-in {
// //   background-image: svg('src/components/icons/Chevron_close_icon.svg');
// //   background-repeat: no-repeat;
// //   background-position-x: center;
// //   background-position-y: center;
// // }

// // /* Bug #16804
// //  * A rogue scroll bar is appearing when rows a pinned to the right of the grid. This appears
// //  * to happen when SemanticUI's CSS is loaded, so I think they are tripping over each other.
// //  * Making the background of the container white seems to hide the issue.
// //  * I've been unable to track down the underlying cause, but I don't want to spend too much time on a cosmetic issue.
// //  * TMCG 13th Jun '19
// //  */
// // div.ag-pinned-right-cols-container {
// //   background-color: white;
// // }

// // #company-confirmation-modal,
// // #share-confirmation-modal {
// //   font-family: $font-family-default;
// //   position: fixed;
// //   width: 100%;
// //   height: 100%;
// //   top: 0;
// //   left: 0;
// //   right: 0;
// //   bottom: 0;
// //   background-color: rgba(0, 0, 0, 0.3);
// //   z-index: 2;

// //   div.wrapper {
// //     position: absolute;
// //     top: 50%;
// //     left: 50%;
// //     background: url(components/images/BlankBackground.png) 0 0px no-repeat;
// //     transform: translate(-50%, -50%);
// //     height: 464px;
// //     width: 418px;
// //     padding-left: 8px;
// //     padding-top: 6px;

// //     div.header {
// //       margin: 40px 0 0 30px;
// //       text-align: left;
// //       height: 60px;

// //       h3 {
// //         font-weight: 700;
// //         font-size: 17px;
// //         display: inline;
// //       }

// //       .close {
// //         background: url(components/icons/CloseIcon.svg) 0 0px no-repeat;
// //         cursor: pointer;
// //         height: 14px;
// //         width: 14px;
// //         margin: 6px 30px 0 0;
// //         float: right;
// //         color: black;
// //       }
// //     }

// //     div.content {
// //       margin-left: 30px;
// //       width: 348px;
// //       white-space: normal;

// //       span.highlight {
// //         font-weight: $font-weight-bold;
// //       }
// //     }

// //     div.footer {
// //       position: absolute;
// //       bottom: 0;
// //       left: 8px;
// //       width: 410px;
// //       height: 99px;
// //       text-align: right;
// //       padding-top: 30px;
// //       padding-right: 30px;
// //     }
// //   }
// // }
// @mixin grid-row-bkg($color) {
//   background: $color;

//   @if (lightness($color) < 50) {
//     .ag-icon {
//       filter: invert(100%);
//     }
//   }

//   &.ag-row-hover {
//     background: set-row-bkg-adj-color($color, 5%);
//   }

//   &.ag-row-selected {
//     background: set-row-bkg-adj-color($color, 15%);
//   }
// }

// @mixin grid-pill($background-color: inherit, $color: white) {
//   padding: $clk-pill-padding;
//   border-radius: 4px;
//   background-color: $background-color;
//   color: $color;
//   font-weight: 400;
//   line-height: 14px;
//   text-align: center;
//   height: 14px;
// }

// .grid-row-clarksons-private,
// .ag-row-hover.grid-row-clarksons-private,
// .grid-cell-private-clarksons-public,
// .ag-row-hover.grid-cell-private-clarksons-public {
//   color: $grid-row-clarksons-private-fg-color;
//   background-color: $grid-row-clarksons-private-bg-color;
// }

// .grid-row-market-public {
//   color: $grid-row-market-public-fg-color;
// }

// .grid-row-market-private {
//   color: $grid-row-clarksons-public-fg-color;
//   background-color: $grid-row-clarksons-public-bg-color;
// }

// .grid-row-clarksons-public {
//   color: $grid-row-clarksons-public-fg-color;
// }

// .grid-row-reported-clarksons-public {
//   color: $grid-row-clarksons-public-fg-color-faded;
// }

// .grid-row-market-private,
// .ag-row-hover.grid-row-market-private,
// .grid-row-reported-market-private,
// .ag-row-hover.grid-row-reported-market-private {
//   color: $grid-row-market-private-fg-color;
//   background-color: $grid-row-market-private-bg-color;
// }

// .grid-row-faded-out {
//   color: $grid-row-fg-faded !important;
// }

// div.grid-row-deleted {
//   text-decoration: line-through;

//   & div.ag-cell {
//     text-decoration: line-through;
//   }
// }

// .grid-row-own-program {
//   color: $grid-row-own-program-fg-color !important;
// }

// .grid-row-reported-own-program {
//   color: $grid-row-own-program-fg-color-faded !important;
// }

// .grid-cell-pill-orange {
//   @include grid-pill($clk-orange);
// }

// .grid-cell-pill-grey {
//   @include grid-pill($clk-grey);
// }

// .grid-cell-pill-green {
//   @include grid-pill($clk-green);
// }

// .grid-cell-pill-red {
//   @include grid-pill($clk-pink);
// }

// .grid-cell-pill-blank {
//   padding: $clk-pill-padding;
// }

// .grid-cell-pill-black {
//   @include grid-pill($clk-black);
// }

// .grid-group-row-clarksons {
//   color: $grid-group-row-clarksons-color;
//   border: $fort-border !important;
//   font-weight: bolder;
//   line-height: $row-height;
//   @include grid-row-bkg($grid-group-row-clarksons-background-color);

//   .ag-group-expanded,
//   .ag-group-contracted {
//     margin-left: 15px;

//     .ag-icon {
//       margin-right: 12px !important;
//     }
//   }
// }

// .grid-cell-private {
//   color: $grid-row-market-private-fg-color;
//   background-color: $grid-row-market-private-bg-color;
// }

// .ag-row-focus {
//   @include grid-row-bkg($selected-color);
// }

// div.suppress-focus:focus {
//   outline: none;
// }

// div.live-updates-diconnected-banner {
//   background-color: $clk-red;
// }

// %message {
//   width: 100%;
//   padding: 0.5em;
//   font-size: 0.95em;
//   font-family: $font-family-default;

//   h1 {
//     display: inline-block;
//     min-width: 13em;
//     margin-right: 1em;
//     /*Reset browser defaults*/
//     font-weight: normal;
//     margin-bottom: 0;
//     font-size: 1.15em;
//   }

//   section {
//     display: block;

//     &:not(:first-child) {
//       margin-top: 0.5em;
//     }
//   }
// }

// #version-updated-message {
//   font-family: $font-family-default;
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.3);
//   z-index: 2;
//   cursor: pointer;
//   text-align: center;
// }

// #version-updated-message>div {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   background-color: #fff;
//   transform: translate(-50%, -50%);
//   height: 465px;
//   width: 745px;
// }

// #version-updated-message>div>div.message-header {
//   height: 150px;
//   background-image: url('SEA FORT logo dark large.png');
//   background-size: 170px 50px;
//   background-repeat: no-repeat;
//   background-position-y: 50%;
//   background-position-x: 50%;
// }

// #version-updated-message>div>div.info-message {
//   background-color: #195d95;
//   display: flex;
//   align-items: center;
//   min-height: 72px;
//   padding: 0 8em;
// }

// #version-updated-message>div>div.info-message>span {
//   font-size: 18px;
//   font-weight: $font-weight-medium;
//   width: 100%;
// }

// #version-updated-message>div>div.message-content {
//   color: rgba(0, 0, 0, 0.54);
//   font-size: 18px;
//   font-weight: $font-weight-regular;
//   padding: 2em 0;
// }

// #version-updated-message>div>button {
//   border-radius: 5px;
//   -webkit-box-shadow: none;
//   box-shadow: none;
//   border: none;
//   background-color: #2598f3;
//   color: #ffffff;
//   padding: 10px;
//   cursor: pointer;
//   text-transform: uppercase;
//   outline: none;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-align: center;
//   align-items: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   width: 180px;
//   margin: 0 auto 5em;
//   font-size: 14px;
//   font-weight: $font-weight-bold;
// }

// #version-updated-message>div>div.message-footer {
//   color: rgba(0, 0, 0, 0.5);
//   font-size: 14px;
//   font-weight: $font-weight-medium;
//   padding: 1em 0;
// }

// .info-message {
//   @extend %message;

//   background-color: $info-background-color;
//   color: $info-text-color;
// }

// .error-message {
//   @extend %message;

//   background-color: $error-background-color;
//   color: $error-text-color;
// }

// .notification-messages {
//   flex-shrink: 0;
//   /*Prevents lack of expanding to show full contents while < max-height - https://stackoverflow.com/a/43666293 (probably needed because the contents are flex) */

//   .content {
//     position: absolute;
//     width: 100%;
//     overflow-y: auto;
//     max-height: 12em;
//     box-shadow: $brand-header-box-shadow;
//     background-color: inherit;

//     .notification-messages-item {
//       &:not(:first-child) {
//         margin-top: 0.3em;
//       }
//     }
//   }
// }

// .ag-popup-editor {
//   z-index: 1001 !important;
//   /* Ag-grid library's default z-index is 1, which is enough to hover over vanilla content, but we need more, so that cell editing never gets obstructed by the navigation menus etc. `!important` becase the library puts its '1'  in a more specific selector due to usage of a theme-specific class. */
// }

// .brand-header-part {
//   background-color: $brand-header-background-color;

//   &,
//   & * {
//     &::-webkit-scrollbar-thumb {

//       &,
//       &:window-inactive {
//         background-color: $brand-header-text-color;
//       }
//     }
//   }
// }

// .transition-fade-500in-200out-enter {
//   opacity: 0.01;
// }

// .transition-fade-500in-200out-enter-active {
//   opacity: 1;
//   transition: opacity 500ms ease-in;
// }

// .transition-fade-500in-200out-exit {
//   opacity: 1;
// }

// .transition-fade-500in-200out-exit-active {
//   opacity: 0.01;
//   transition: opacity 200ms ease-in;
// }

// .ui.dimmer {
//   /*Generic modal dialog overlay.*/
//   z-index: $in-app-modal-dialog-z-index;
// }

// .ui.menu,
// .brand-header-part {
//   z-index: $in-app-modal-dialog-z-index + 1;
//   /*Keep the brand header above generic dialogs. Let user still read app notifications or navigate out of the app just like he can use browser navigation bar. */
// }

// .linkButton {
//   cursor: pointer;
//   color: $clk-blue;
// }

// .linkButton:hover {
//   text-decoration: underline;
// }

// // The filter input's height is set to 15px by the ag-material theme, which causes the filter input boxes to get squashed.
// input.ag-filter-filter {
//   height: auto !important;
// }

// #clearButton {
//   cursor: pointer;
// }

// .MuiTypography-body1 {
//   font-size: inherit !important;
// }

// // disable animations for all treeviews
// .MuiTreeView-root *,
// .MuiTreeView-root * :before,
// .MuiTreeView-root * :after {
//   animation: initial !important;
//   transition: initial !important;
// }

// .fort-grid-tooltip {
//   font-family: 'Roboto', 'Cera GR Light', sans-serif;
//   opacity: 1 !important;
//   background-color: $header-background-color !important;
//   border-color: $fort-border-color !important;

//   th {
//     text-align: right;
//     color: $clk-grey;
//     font-weight: normal;
//   }

//   td {
//     color: $clk-black;
//   }
// }

// .data-size-selector {
//   width: 200px;
//   padding-left: 10px;
//   padding-top: 10px;
// }

// div.ag-root span.ag-icon.ag-icon-checkbox-checked {
//   background: url(components/icons/Checkbox_active.svg) no-repeat;
//   background-size: 20px 20px;
//   height: 20px;
//   left: 5px;
//   position: relative;
//   top: 2.5px;
//   width: 20px;
// }

// div.ag-root span.ag-icon.ag-icon-checkbox-unchecked {
//   background: url(components/icons/Checkbox_empty.svg) no-repeat;
//   background-size: 20px 20px;
//   height: 20px;
//   left: 5px;
//   position: relative;
//   top: 2.5px;
//   width: 20px;
// }

// div.ag-root span.ag-icon.ag-icon-checkbox-indeterminate {
//   background: url(components/icons/Checkbox_indeterminate.svg) no-repeat;
//   background-size: 20px 20px;
//   height: 20px;
//   left: 5px;
//   position: relative;
//   top: 2.5px;
//   width: 20px;
// }

// #export-options {
//   font-family: $font-family-default;
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.3);
//   z-index: 2;
// }

// #export-options>div {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   background: url(components/images/Background.png) 0 0px no-repeat;
//   transform: translate(-50%, -50%);
//   height: 464px;
//   width: 418px;
// }

// #export-options>div>div.export-options-header {
//   margin: 40px 0 0 15px;
//   text-align: left;
//   height: 60px;
// }

// #export-options>div>div.export-options-header>h3 {
//   font-weight: 700;
//   font-size: 17px;
//   display: inline;
//   margin-left: 25px;
// }

// #export-options>div>div.export-options-header>div#export-options-close {
//   /*background: url(components/icons/close.svg) 0 0px no-repeat;*/
//   cursor: pointer;
//   font-weight: $font-weight-bold;
//   font-size: large;
//   height: 14px;
//   width: 14px;
//   margin: 13px 48px 0 0;
//   float: right;
// }

// #export-options>div>div.export-options-content {
//   font-size: $secondary-font-size;
//   height: 260px;
//   text-align: center;
// }

// #export-options>div>div.export-options-content>div.export-options-options {
//   height: 120px;
//   padding-top: 30px;
// }

// #export-options>div>div.export-options-content>div.export-options-options>div.export-options-option {
//   height: 90px;
//   width: 90px;
//   float: left;
//   font-family: $font-family-default;
// }

// #export-options>div>div.export-options-content>div.export-options-options>div.export-options-option:first-child {
//   margin: 0 40px 0 100px;
// }

// #export-options>div>div.export-options-content>div.export-options-options>div.export-options-option>input[type='checkbox'] {
//   display: none;
// }

// #export-options>div>div.export-options-content>div.export-options-options>div.export-options-option>input[type='checkbox']+label {
//   cursor: pointer;
//   height: 100px;
//   width: 100px;
//   display: inline-block;
//   padding: 0 0 0 0px;
// }

// #export-options>div>div.export-options-content>div.export-options-options>div.export-options-option>input[type='checkbox']:checked+label {
//   height: 100px;
//   width: 100px;
//   display: inline-block;
//   padding: 0 0 0 0px;
// }

// #export-options>div>div.export-options-content>div.export-options-options>div.export-options-option>input[type='checkbox']+label.email {
//   background: url(components/icons/email_unselected.svg) 0 0px no-repeat;
// }

// #export-options>div>div.export-options-content>div.export-options-options>div.export-options-option>input[type='checkbox']:checked+label.email {
//   background: url(components/icons/email_selected.svg) 0 0px no-repeat;
// }

// #export-options>div>div.export-options-content>div.export-options-options>div.export-options-option>input[type='checkbox']+label.excel {
//   background: url(components/icons/excel_unselected.svg) 0 0px no-repeat;
// }

// #export-options>div>div.export-options-content>div.export-options-options>div.export-options-option>input[type='checkbox']:checked+label.excel {
//   background: url(components/icons/excel_selected.svg) 0 0px no-repeat;
// }

// #export-options>div>div.export-options-content>div.export-options-options>div.ui.input {
//   width: 225px;
// }

// #export-options>div>div.export-options-footer {
//   height: 100px;
//   padding: 35px 0 0 0;
// }

// #export-options>div>div.export-options-footer>div {
//   float: right;
// }

// #export-options>div>div.export-options-footer>div>a[name='close-button'] {
//   font-weight: $font-weight-bold;
//   cursor: pointer;
//   float: left;
//   margin: 10px 25px;
//   height: 25px;
//   width: 50px;
// }

// #export-options>div>div.export-options-footer>div>button {
//   margin-right: 25px;
// }

// div.menu-button>i.activity-icon {
//   background-image: svg('src/components/icons/Activity_icon.svg');
//   height: 19px;
//   width: 23px;
//   margin-right: 5px;
// }

// div.menu-button:hover>i.activity-icon {
//   background-image: svg('src/components/icons/Activity_active_icon.svg');
// }

// div#activity.activity-compact>div>div#activity-menu>div.mainmenu>div.menu-toggle-format {
//   background-image: svg('src/components/icons/Expand_icon.svg');
//   background-repeat: no-repeat;
//   height: 14px;
//   width: 14px;
// }

// div#activity.activity-expanded>div>div#activity-menu>div.mainmenu>div.menu-toggle-format {
//   background-image: svg('src/components/icons/Retract_icon.svg');
//   background-repeat: no-repeat;
//   height: 14px;
//   width: 14px;
// }

// div#activity.activity-compact>div>div#activity-menu>div.mainmenu>div.menu-slide-in {
//   background-image: svg('src/components/icons/Chevron_close_icon.svg');
//   background-repeat: no-repeat;
//   background-position-x: center;
//   background-position-y: center;
// }

// /* Bug #16804
//  * A rogue scroll bar is appearing when rows a pinned to the right of the grid. This appears
//  * to happen when SemanticUI's CSS is loaded, so I think they are tripping over each other.
//  * Making the background of the container white seems to hide the issue.
//  * I've been unable to track down the underlying cause, but I don't want to spend too much time on a cosmetic issue.
//  * TMCG 13th Jun '19
//  */
// div.ag-pinned-right-cols-container {
//   background-color: white;
// }

// #company-confirmation-modal,
// #share-confirmation-modal {
//   font-family: $font-family-default;
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.3);
//   z-index: 2;

//   div.wrapper {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     background: url(components/images/BlankBackground.png) 0 0px no-repeat;
//     transform: translate(-50%, -50%);
//     height: 464px;
//     width: 418px;
//     padding-left: 8px;
//     padding-top: 6px;

//     div.header {
//       margin: 40px 0 0 30px;
//       text-align: left;
//       height: 60px;

//       h3 {
//         font-weight: 700;
//         font-size: 17px;
//         display: inline;
//       }

//       .close {
//         background: url(components/icons/CloseIcon.svg) 0 0px no-repeat;
//         cursor: pointer;
//         height: 14px;
//         width: 14px;
//         margin: 6px 30px 0 0;
//         float: right;
//         color: black;
//       }
//     }

//     div.content {
//       margin-left: 30px;
//       width: 348px;
//       white-space: normal;

//       span.highlight {
//         font-weight: $font-weight-bold;
//       }
//     }

//     div.footer {
//       position: absolute;
//       bottom: 0;
//       left: 8px;
//       width: 410px;
//       height: 99px;
//       text-align: right;
//       padding-top: 30px;
//       padding-right: 30px;
//     }
//   }
// }
