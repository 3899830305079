// ag-grid selectors
$ag-cell: '.ag-cell';
$ag-cell-range-selected: '.ag-cell-range-selected';
$ag-cell-single-selected: '.ag-cell-range-single-cell';
$ag-row: '.ag-row';
$ag-row-selected: '.ag-row-selected';
$ag-header: '.ag-header';
$ag-header-cell: '.ag-header-cell';
$ag-group-header: '.ag-row-group';
$ag-checkbox-unchecked: '.ag-checkbox-input-wrapper';
$ag-checkbox-checked: '.ag-checkbox-input-wrapper.ag-checked';
$ag-checkbox-indeterminate: '.ag-checkbox-input-wrapper.ag-indeterminate';
$ag-checkbox: '#{$ag-checkbox-unchecked}, #{$ag-checkbox-checked}, #{$ag-checkbox-indeterminate}';
$ag-checkbox-icon: '#{$ag-checkbox-unchecked}::after, #{$ag-checkbox-checked}::after, #{$ag-checkbox-indeterminate}::after';
$ag-checkbox-icon-background: '#{$ag-checkbox-unchecked}::before, #{$ag-checkbox-checked}::before, #{$ag-checkbox-indeterminate}::before';
$ag-pinned-column-separating-border: '.ag-pinned-left-header, .ag-cell-last-left-pinned';
$ag-pinned-header: '.ag-pinned-left-header';
$ag-pinned-left-cols-container: '.ag-pinned-left-cols-container';
$ag-status-bar: '.ag-status-bar';
$ag-icon: '.ag-icon';
$ag-react-container: '.ag-react-container';
$ag-header-cell-filtered: '.ag-header-cell-filtered';
$ag-header-icon: '.ag-header-icon';
$ag-header-filter-icon: '#{$ag-header-icon}.ag-filter-icon';
$ag-header-sort-ascending-icon: '#{$ag-header-icon}.ag-sort-ascending-icon';
$ag-header-sort-descending-icon: '#{$ag-header-icon}.ag-sort-descending-icon';
$ag-header-sort-icons: '#{$ag-header-sort-ascending-icon}, #{$ag-header-sort-descending-icon}';
$ag-header-cell-menu: '.ag-header-cell-menu-button';
$ag-cell-inline-editing: '.ag-cell-inline-editing';
$ag-popup-editor: '.ag-popup-editor';
$ag-cell-edit-input: '.ag-cell-edit-input';
$ag-body: '.ag-body-viewport';
$ag-menu: '.ag-menu';
$ag-menu-tab: '.ag-tab';
$ag-menu-list: '.ag-menu-list';
$ag-menu-option: '.ag-menu-option';
$ag-menu-separator: '.ag-menu-separator';
$native-ag-filter: '.ag-filter';
$ag-set-filter-select-all: '.ag-set-filter-select-all';
$ag-filter-apply-panel: '.ag-filter-apply-panel';
$ag-column-select-column: '.ag-column-select-column';
$ag-row-level-0: '.ag-row-level-0';
$ag-row-level-1: '.ag-row-level-1';
$ag-row-level-2: '.ag-row-level-2';
$ag-row-level-3: '.ag-row-level-3';
$ag-row-level-4: '.ag-row-level-4';
$ag-row-level-5: '.ag-row-level-5';
$ag-row-level-6: '.ag-row-level-6';
$ag-row-level-7: '.ag-row-level-7';

// the ag-grid styles must be imported AFTER the ag-grid variables have been set
@import './node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.scss';
@import './node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material/sass/ag-theme-material-mixin.scss';

.ag-theme-material {
    @include ag-theme-material(
        (
            material-primary-color: var(--primary-color),
            checkbox-checked-color: var(--primary-color),
            range-selection-border-color: var(--primary-color),
            cell-horizontal-padding: 5px,
            list-item-height: 20px,
        )
    );
}

.MuiFormControlLabel-labelPlacementStart {
    .MuiTypography-body1 {
        font-family: 'Cera GR Medium' !important;
        font-size: 13px !important;
    }
}

.grid {
    // misc
    --row-height: 30px; // should be synced with settings in js
    --row-height-condensed: 20px; // should be synced with settings in js
    --heading-border: 1px dotted var(--grey-2);
    --input-border: 1px solid var(--grey-4);
    --input-border-focus: 1px solid var(--blue-1);
    --box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);

    .grid-heading-privacy {
        font-family: agGridMaterial;

        // the styles were copied from ag-icon with material theme
        .ag-header-cell-text {
            font-family: 'agGridMaterial';
            display: block;
            font-size: 18px;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;

            &:before {
                content: 'eye';
                outline: none;
            }
        }
    }

    // ag-grid style overrides misc
    .ag-selection-checkbox {
        height: auto !important;
    }
    #{$ag-checkbox} {
        box-shadow: none !important;
    }
    #{$ag-checkbox-icon-background} {
        content: '';
        pointer-events: none;
        z-index: 0;
        top: 1px;
        left: 1px;
        width: 16px;
        height: 15px;
        position: absolute;
        background-color: white;
    }
    #{$ag-column-select-column} {
        margin-bottom: 4px !important;
    }
    #{$ag-set-filter-select-all} {
        padding: 4px 0;
        margin-bottom: 4px;
    }
    #{$native-ag-filter} {
        .ag-text-field-input {
            height: 20px !important;
        }
        .ag-filter-apply-panel {
            padding: 4px 12px;
        }
        input[type='date'] {
            width: 100%;
            display: block;
            height: var(--row-height) !important;
            border: var(--input-border) !important;
            border-bottom: var(--input-border) !important;
            padding: 5px !important;
            outline: none;
            background: var(--white-1);
            font-size: 13px;

            &:focus {
                border: var(--input-border-focus);
            }
        }
        .MuiTreeItem-content {
            input[type='checkbox'] {
                top: 2px !important;
            }
        }
    }
    #{$ag-status-bar} {
        max-height: 36px;
        padding-left: 0;
    }
    #{$ag-menu} {
        border: var(--input-border-focus);
    }
    #{$ag-menu-tab} {
        border: none !important;
    }
    #{$ag-menu-option} {
        display: flex;
        align-items: center;
        height: var(--row-height-condensed);

        .ag-menu-option-icon {
            min-width: 18px;
        }

        > * {
            padding: 0;
            &:not(:last-child) {
                margin-left: 15px;
            }
        }
    }
    #{$ag-menu-separator} {
        display: flex;
        align-items: center;
        height: auto;
        margin: 4px 0;
        border-bottom: 1px solid var(--grey-1);

        > * {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    #{$ag-body},
    #{$ag-popup-editor} {
        input {
            width: 100% !important;
            display: block !important;
            height: var(--row-height) !important;
            border: 1px solid var(--grey-4) !important;
            padding: 5px !important;
            outline: none !important;
            background: var(--white-1) !important;
            font-size: 13px !important;

            &:focus {
                border: var(--input-border-focus);
            }
        }
    }
    #{$ag-popup-editor} {
        border: var(--input-border-focus);
        background-color: var(--white-1);

        input {
            border: none !important;
        }
    }

    // ag-grid style overrides for header
    #{$ag-header} {
        background-color: var(--clarksons-base-darker-1);
    }
    #{$ag-header-cell} {
        font-weight: normal;
        color: var(--font-color-secondary);
        border-right: var(--heading-border);

        #{$ag-header-icon} {
            margin: 0;

            &:hover {
                #{$ag-icon} {
                    color: var(--blue-1);
                }
            }
        }

        #{$ag-header-sort-icons} {
            flex: 1;
        }

        #{$ag-header-cell-menu} {
            padding: 5px 0 5px 10px;
        }

        &#{$ag-header-cell-filtered} {
            #{$ag-header-cell-menu} {
                opacity: 1 !important;

                #{$ag-icon} {
                    &:before {
                        content: 'group';
                    }
                }
            }
        }
    }
    #{$ag-group-header} {
        background-color: rgba(0, 0, 0, 0.09);
    }
    #{$ag-pinned-header} {
        #{$ag-header-cell} {
            border-right: none;

            &:last-child {
                border-right: var(--heading-border);
            }
        }
    }
    #{$ag-header-filter-icon} {
        display: none;
    }
    .selectEditor-row {
        min-height: var(--row-height);
    }

    // ag-grid style overrides for body
    #{$ag-body} {
        svg {
            display: block;
        }
        svg.visibility {
            flex-shrink: 0;
            height: var(--row-height);
        }

        #{$ag-react-container} {
            width: 100%;
        }
        #{$ag-icon} {
            transition: color 0.2s ease-out;
        }
        #{$ag-row} {
            &#{$ag-row-selected} {
                background-color: var(--blue-2);

                #{$ag-cell} {
                    background-color: var(--blue-2);
                    color: var(--black-1);
                }
            }

            .ag-row-group {
                display: flex;
                justify-content: center;
                padding-left: 4px !important;
            }

            .ag-icon-tree-closed,
            .ag-icon-tree-open {
                position: absolute;
                left: 4px;
            }

            &#{$ag-row-level-0} {
                .ag-row-group-indent-0 {
                    background-color: var(--group-grey-0);
                }
                .ag-group-child-count,
                .ag-group-value {
                    font-weight: 900;
                    font-size: large;
                }
            }

            &#{$ag-row-level-1} {
                .ag-row-group-indent-1 {
                    background-color: var(--group-grey-1);
                }

                .ag-icon-tree-closed,
                .ag-icon-tree-open {
                    margin-left: 25px;
                }

                .ag-group-child-count,
                .ag-group-value {
                    font-weight: 600;
                    font-size: larger;
                }
            }

            &#{$ag-row-level-2} {
                .ag-row-group-indent-2 {
                    background-color: var(--group-grey-2);
                }

                .ag-icon-tree-closed,
                .ag-icon-tree-open {
                    margin-left: 50px;
                }
            }

            &#{$ag-row-level-3} {
                .ag-row-group-indent-3 {
                    background-color: var(--group-grey-2);
                }

                .ag-icon-tree-closed,
                .ag-icon-tree-open {
                    margin-left: 75px;
                }
            }

            &#{$ag-row-level-4} {
                .ag-row-group-indent-4 {
                    background-color: var(--group-grey-2);
                }

                .ag-icon-tree-closed,
                .ag-icon-tree-open {
                    margin-left: 100px;
                }
            }

            &#{$ag-row-level-5} {
                .ag-row-group-indent-5 {
                    background-color: var(--group-grey-2);
                }

                .ag-icon-tree-closed,
                .ag-icon-tree-open {
                    margin-left: 125px;
                }
            }

            &#{$ag-row-level-6} {
                .ag-row-group-indent-6 {
                    background-color: var(--group-grey-2);
                }

                .ag-icon-tree-closed,
                .ag-icon-tree-open {
                    margin-left: 150px;
                }
            }

            &#{$ag-row-level-7} {
                .ag-row-group-indent-7 {
                    background-color: var(--group-grey-2);
                }

                .ag-icon-tree-closed,
                .ag-icon-tree-open {
                    margin-left: 175px;
                }
            }
        }
        #{$ag-cell} {
            display: flex;
            align-items: center;
            line-height: 100%;
            font-weight: bold;

            &#{$ag-cell-range-selected}:not(#{$ag-cell-single-selected}) {
                background-color: var(--blue-2);
                color: var(--black-1);
            }

            &#{$ag-cell-inline-editing} {
                background: none;
                height: auto !important;
                border: 0 !important;
                padding: 0 !important;
                outline: none !important;
                border-radius: 0 !important;

                input {
                    display: block !important;
                    height: var(--row-height) !important;
                    border: var(--input-border) !important;
                    padding: 5px !important;
                    outline: none !important;
                    background: var(--white-1) !important;
                    border: 1px solid var(--primary-color) !important;
                    font-size: 13px !important;
                    &:focus {
                        border: 1px solid (var(--blue-1)) !important;
                    }
                }
            }
        }

        // custom row/cell classes; classname sequence matters
        .grid-row-faded-out {
            color: var(--grey-2);
        }
        .grid-row-own-program {
            color: var(--blue-1);
        }
        .grid-row-clarksons-private {
            color: var(--pink-2);
            background: var(--black-1);
        }
        .grid-row-market-private {
            background-color: var(--pink-2);
        }
        .grid-row-clarksons-public {
            color: var(--pink-3);
        }
        .grid-row-deleted {
            > * {
                text-decoration: line-through;
            }
        }
        .grid-cell-private {
            background-color: var(--pink-2);
        }

        .grid-cell-rumoured {
            color: var(--grey-2);
        }

        .grid-row-new {
            color: var(--green-1);
        }

        .grid-row-on-subs {
            background-color: var(--pink-4);
            color: var(--black-1);
        }

        .grid-row-clarksons-on-subs {
            background-color: var(--pink-4);
            color: var(--red-2);
        }

        .grid-row-converted {
            color: var(--blue-1);
        }

        // status pills
        .grid-cell-pill {
            display: flex;
            width: fit-content;
            align-items: center;
            justify-content: center;
            padding: 5px 15px;
            line-height: 100%;
            border-radius: 15%;
            color: var(--white-1);
            text-shadow: 1px 1px 1px var(--grey-2);

            &-blank {
                color: inherit;
            }
            &-green {
                background-color: var(--green-1);
            }
            &-red {
                background-color: var(--pink-1);
            }
            &-black {
                background-color: var(--black-1);
            }
            &-grey {
                background-color: var(--grey-1);
            }
            &-orange {
                background-color: var(--orange-1);
            }
        }
    }

    .ag-cell.errored {
        border-color: var(--red-1);
        background-image: svg('src/components/icons/WarningIconRed.svg');
        &.grid-row-market-private,
        &.grid-cell-private {
            background-image: svg('src/components/icons/WarningIconWhite.svg');
            border-color: white;
        }

        border-width: 1px;
        border-style: solid;
        background-size: 18px 16px;
        background-repeat: no-repeat;
        background-position: right 0.2em center;
    }
}

.grid-condensed {
    font-size: 9px;

    svg.visibility {
        height: var(--row-height-condensed);
    }

    #{$ag-checkbox} {
        font-size: 14px;
    }

    #{$ag-checkbox-icon-background} {
        top: 2px;
        left: 1px;
        width: 12px;
        height: 12px;
    }

    #{$ag-body} {
        .grid-cell-pill {
            padding: 3px 9px;
        }
    }
}

// filter css
.filter {
    padding: 0 5px 6px;

    > * {
        &:not(:last-child) {
            border-bottom: 1px solid var(--grey-4);

            margin-bottom: 10px;
            padding-bottom: 10px;
        }
    }

    .filter-section {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .filter-footer {
        display: flex;
        justify-content: flex-end;

        button {
            background: none;
            border: none;
            color: var(--blue-1);
            text-transform: uppercase;
            cursor: pointer;
        }
    }

    .MuiTreeView-root {
        max-height: 325px;
        overflow: auto;
    }
}
.filterCheckbox {
    display: flex;
    align-items: center;

    .filterCheckbox-icon {
        display: flex;
        align-items: center;

        &::after {
            font-family: agGridMaterial;
            content: '\f108';
        }

        &.checked {
            color: var(--primary-color);
            &::after {
                content: '\f106';
            }
        }
    }

    span {
        margin-right: 5px;
    }
}

// editor css
#navbar {
    // we enforce the seaheader to hide behind our overlay content, i.e. .selectEditor-overlay
    position: relative;
    z-index: 1;
}
.selectEditor-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.selectEditor {
    outline: none !important;
    position: relative;
    min-width: 175px;
    width: 100%;

    .selectEditor-row {
        display: flex;
        align-items: center;
        padding: 0 7px;
    }
    .selectEditor-value {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .selectEditor-row-selected {
        background-color: var(--grey-1);
    }
}

.share-confirmation,
.export-options,
.company-confirmation {
    > div {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    h3 {
        margin-bottom: 25px;
    }

    button {
        margin: 0 0 0 0.25em !important;
    }
}

.vesselSelect {
    min-width: 350px;
}

.multiSelect {
    display: flex;
    align-items: center;

    .multiSelect-items {
        display: flex;
        align-items: center;
    }

    .multiSelect-input-container {
        height: var(--row-height);
        overflow: visible;
    }

    .multiSelect-input {
        .downshift-container {
            flex-shrink: 0;
        }
        .downshift-content {
            margin: 0 -1px;
            border: 1px solid var(--blue-1);
            border-top: none;
            background-color: var(--white-1);
            box-shadow: var(--box-shadow);
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
        }
    }

    .multi-select-item {
        flex-shrink: 0;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.multiple-location-select {
    --pill-height: 26px;
    --pill-margin: 10px;

    // height: 250px;
    width: 600px;
    margin: var(--pill-margin) 0 0 0;
    margin-right: var(--pill-margin);

    *:not(input) {
        transition: width 0.15s ease-out;
    }

    .multiple-location-select-items {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        > * {
            margin-bottom: var(--pill-margin);
        }
    }

    .multi-select-item-separator {
        padding-left: 5px;
        padding-right: 5px;
        font-weight: bold;
    }

    .multi-select-item:first-child {
        padding-left: 15px;
    }

    .multi-select-item-pill {
        display: flex;
    }

    .multi-select-item-selected-pill {
        box-shadow: 0 0 0 1px #2684ff !important;
        border-radius: 4px !important;
    }

    .multi-select-item-pill-separator {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 37px;
        font-weight: bold;
        background: #e8e8e8;
        border-radius: 4px;
    }

    .multi-select-space-pill {
        width: var(--pill-margin);
        display: inline-block;
    }

    .multipleLocationSelect-input-container {
        flex-shrink: 0;
        margin: 0 0 var(--pill-margin) var(--pill-margin);
        height: var(--pill-height);
        overflow: visible;
    }
    .multipleLocationSelect-input {
        .downshift-container {
            position: relative;
        }
        .downshift-content {
            position: absolute;
            left: 0;
            z-index: 2;
            min-width: 320px;
            border: 1px solid var(--blue-1);
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
            background-color: var(--white-1);
            box-shadow: var(--box-shadow);
        }
        input {
            padding: 0;
            height: var(--pill-height);
        }
    }
}

.export-options {
    display: flex;
    flex-direction: column;
    align-items: center;

    .export-options-formats {
        display: flex;

        > * {
            margin-right: 10px;
        }
    }
    .export-options-format {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        svg {
            width: 75px !important;
            height: auto !important;
            display: block;
            color: grey;
        }

        &:hover,
        &.selected {
            svg {
                color: var(--blue-1);
            }
        }
    }
}

.MuiFormControlLabel-root {
    .MuiTypography-body1 {
        font-family: 'Cera GR Medium' !important;
        font-size: 13px !important;
    }
}

.ag-react-container {
    width: 100%;
}

.ag-column-drop-vertical {
    max-height: 5000px !important;
}

.ag-tool-panel-wrapper {
    min-width: 250px !important;
}

.grid .ag-popup-editor {
    min-width: auto !important;
}

.new-theme {
    #{$ag-pinned-header} {
        #{$ag-header-cell} {
            &:first-child {
                direction: rtl;
                padding: 4px !important;
            }
        }
    }

    #{$ag-pinned-left-cols-container} {
        #{$ag-cell} {
            &:first-child {
                display: flex;
                justify-content: flex-end;
                padding: 4px !important;
            }
        }
    }

    #{$ag-header-cell} {
        overflow: hidden;
        color: #000;
        text-overflow: ellipsis;
        font-family: 'Noto Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 18px;
    }

    #{$ag-body} {
        #{$ag-row} {
            .ag-row-group {
                display: flex;
                justify-content: left;
            }

            &#{$ag-row-level-0} {
                .ag-row-group-indent-0 {
                    background: rgba(32, 49, 113, 0.8);
                    padding-left: 25px !important;
                }
                .ag-group-child-count,
                .ag-group-value {
                    overflow: hidden;
                    color: #fff;
                    text-overflow: ellipsis;
                    font-family: 'Noto Sans';
                    font-size: 14px !important;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                }
                .ag-icon-tree-closed,
                .ag-icon-tree-open {
                    color: white;
                }
            }

            &#{$ag-row-level-1} {
                .ag-row-group-indent-1 {
                    background: rgba(255, 255, 255, 1) !important;
                    padding-left: 50px !important;
                }
                .ag-group-child-count,
                .ag-group-value {
                    overflow: hidden;
                    color: #000;
                    text-overflow: ellipsis;
                    font-family: 'Noto Sans';
                    font-size: 14px !important;
                    font-style: normal;
                    font-weight: 600 !important;
                    line-height: 16px;
                }

                .ag-icon-tree-closed,
                .ag-icon-tree-open {
                    margin-left: 20px !important;
                    color: #000;
                }
            }
            @for $i from 2 to 20 {
                .ag-row-group-indent-#{$i} {
                    padding-left: 75px !important;
                    background: rgba(255, 255, 255, 1) !important;

                    .ag-group-child-count,
                    .ag-group-value {
                        overflow: hidden;
                        color: #000;
                        text-overflow: ellipsis;
                        font-family: 'Noto Sans';
                        font-size: 14px !important;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                    }

                    .ag-icon-tree-closed,
                    .ag-icon-tree-open {
                        margin-left: 45px !important;
                        color: #000;
                    }
                }
            }
        }

        #{$ag-cell} {
            display: block;
            overflow: hidden;
            color: #404040;
            text-overflow: ellipsis;
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 500;
            overflow: hidden;
            border-right-style: solid;
            border-width: 1px;
            border-right-color: rgba(226, 226, 226, 0.5);
            border-left-style: solid;
            border-left-color: rgba(226, 226, 226, 0.5);
        }
        .grid-row-faded-out {
            color: var(--grey-2);
        }

        .grid-row-own-program {
            color: var(--blue-1);
        }

        .grid-row-clarksons-private {
            color: var(--pink-2);
            background: var(--black-1);
        }

        .grid-row-market-private {
            background-color: var(--pink-2);
        }

        .grid-row-clarksons-public {
            color: var(--pink-3);
        }

        .grid-row-deleted {
            > * {
                text-decoration: line-through;
            }
        }

        .grid-cell-private {
            background-color: var(--pink-2);
        }

        .grid-cell-rumoured {
            color: var(--grey-2);
        }

        .grid-row-new {
            color: var(--green-1);
        }

        .grid-row-on-subs {
            background-color: var(--pink-4);
            color: var(--black-1);
        }

        .grid-row-clarksons-on-subs {
            background-color: var(--pink-4);
            color: var(--red-2);
        }

        .grid-row-converted {
            color: var(--blue-1);
        }
    }

    #{$ag-cell-single-selected} {
        border-color: var(--primary-color) !important;
    }

    #{$ag-pinned-left-cols-container} {
        #{$ag-cell} {
            &:first-child {
                border-right-color: transparent;
                display: flex;
            }
            &:last-child {
                border-left-color: transparent;
            }
        }
    }
}
.compact-density {
    --row-height: 24px;

    #{$ag-body} {
        #{$ag-row} {
            // status pills
            .grid-cell-pill {
                width: 20px;
                margin: auto;
                height: 18px;
                margin-top: 2px;
            }
            #{$ag-cell} {
                padding-left: 5px;
                padding-right: 5px;
                font-size: 12px;
                line-height: 24px;
            }
        }
    }
}

.standard-density {
    --row-height: 32px;

    #{$ag-body} {
        #{$ag-row} {
            // status pills
            .grid-cell-pill {
                width: 50px;
                margin: auto;
                margin-top: 3px;
            }

            #{$ag-cell} {
                padding-left: 12px;
                padding-right: 12px;
                font-size: 14px;
                line-height: 32px;
            }
        }
    }
}

.multiSelect-checkbox {
    padding: 2px !important;
}
