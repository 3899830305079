.downshift-container {
    width: 100%;
}

.downshift-container table {
    border-radius: 0 !important;
}

.downshift-container .react-container {
    margin: 0 -1px;
    position: relative;
}

.downshift-menu {
    overflow: auto;
}

.fort-downshift-content {
    position: absolute;
    left: 0;
    z-index: 2;
    width: 100%;
    border: 1px solid var(--blue-1);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color: var(--white-1);
    box-shadow: var(--box-shadow);
}

.company-downshift-content {
    min-width: 350px;
}

.third-party-company-downshift-content {
    min-width: 550px !important;
}

.downshift-general-loader {
    padding: 20px 0;
}

.downshift-filter {
    padding: 5px 5px 10px;
}
