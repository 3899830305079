.parentCompanyDropdown{
    width: 267px;
    left: auto;
    border-color: var(--blue-1);
    bottom: auto !important;
}

.parentCompanyInput{
    width: 100%;

    input {
        width: 100%;
        padding: 9.5px 14px;
        border-radius: 4px;
        border: 1px solid var(--grey-3);
    }
}

.formContent :global{
    .MuiInputLabel-root{
        color: black;
        padding-bottom: 5px;
        font-weight: bold;
    }
    .MuiFormHelperText-root{
        margin-left: 0;
        margin-right: 0;
    }
    .MuiOutlinedInput-root{
        fieldset {
            border-color: var(--grey-3);
        }
        &:hover fieldset {
        border-color: var(--grey-3);
        }
        &.Mui-focused fieldset {
            border-color: black;
        }
    };
    .MuiCheckbox-root {
        color: var(--grey-3) !important;
        &.Mui-checked {
            color: var(--blue-1) !important;
        };
        &:hover {
            background-color: inherit;
        }
    }
}

.countryDropdown{
    max-height: 250px !important;
}

.formHeader{
    background-color: var(--grey-4);
}

.formSubmitButton{
    background-color: var(--blue-1) !important;
    color: white !important;
}

.formCancelButton{
    color: var(--blue-1) !important;
}

.closeButton{
    position: absolute !important;
    right: 8px;
    top: 8px;
    color:black !important;
}

.formErrorContainer{
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: var(--pink-5);
    color: var(--red-1);
}

.formError{
    display: flex;
    flex-direction: column;
    padding-left: 5px;
}

.required:before {
    content:"* ";
    color: red;
  }

  .loader {
    color: white !important;
  }