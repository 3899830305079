div.stem-sizes-dropdown {
    margin: 0;
    top: 100%;
    left: 0;
    position: absolute;
    z-index: 999;
    background-color: #ffffff;
    list-style: none;
    padding: 0;
    border: 1px solid #2684ff;
    color: black !important;
    max-height: 200px;
    overflow: auto;
}

div.stem-sizes-dropdown div {
    height: 40px;
    padding: 10px;
    white-space: nowrap;
}

.stem-sizes-dropdown-item-selected {
    background-color: lightgray;
}

div.stem-sizes-dropdown div:hover {
    background-color: lightgray;
    cursor: pointer;
}
