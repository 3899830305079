div.mainmenu {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 0 0 40px;
}

div.submenu {
    width: 100%;
    min-height: 0;
    height: 60px;
    flex: 0 0 60px;
    padding: 0;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid rgb(212, 212, 213);
}

div.submenu h1 {
    color: #2598f3;
    line-height: 60px;
    padding-right: 10px;
    border-right: 1px solid rgb(212, 212, 213);
}

div.mainmenu .menu-item {
    text-align: center;
    align-self: flex-start;
    height: 100%;
    border-bottom: 5px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

div.mainmenu span.menu-item {
    color: black;
    opacity: 0.8;
    width: 100px;
    font-family: 'Cera GR Medium';
    font-size: 13px;
}

div.mainmenu span.menu-item:hover {
    background-color: lightgray;
    border-bottom: 5px solid #2598f3;
    color: #2196f3;
}

div.mainmenu span.menu-item.active {
    border-bottom: 5px solid #2598f3;
    color: #2196f3;
}

div.submenu div.menu-spacer {
    width: auto;
    height: 100%;
    flex-grow: 1;
}

div.submenu div.menu-item {
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
}

div.submenu .border-right,
div.toolbar .border-right {
    border-right: 1px solid rgb(212, 212, 213);
}

div.submenu .border-left,
div.toolbar .border-left {
    border-left: 1px solid rgb(212, 212, 213);
}

div.submenu span.menu-item {
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    border-left: 1px solid rgb(212, 212, 213);
    color: #000;
}

div.submenu span.menu-button:hover {
    background-color: lightgray;
}

div.submenu div.menu-item:first-child {
    padding-right: 10px;
    padding-left: 0px;
}

div.submenu div.menu-button {
    width: auto;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgb(212, 212, 213);
    cursor: pointer;
}

div.submenu div.menu-button.active {
    background-color: lightgray;
}

div.submenu div.menu-button:hover {
    background-color: lightgray;
}

div.submenu div.menu-item button.primary-button,
div.submenu div.menu-item span.primary-button {
    border-radius: 5px;
    box-shadow: none;
    border: none;
    background-color: #2598f3;
    color: #ffffff;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
}

.primary-button.disabled {
    opacity: 0.3;
}

div.submenu div.menu-item button.primary-button svg,
div.submenu div.menu-item span.primary-button svg {
    margin-right: 5px;
    color: #ffffff;
}

div.submenu div.menu-button svg {
    margin-right: 5px;
    color: lightgray;
}
