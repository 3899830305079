input.group-only-expiry-period-input {
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    border-radius: 0.28571429rem !important;
    margin: 0.3em 5px 0;
    padding: 0.67857143em 1em;
}

input.group-only-expiry-period-input:focus-visible {
    box-shadow: 0 0 0 0 #85b7d9 !important;
    border: 1px solid #85b7d9 !important;
    border-radius: 0.28571429rem !important;
}

button.save {
    margin-left: 5px !important;
}
