.react-toggle-thumb {
    width: 17px;
    height: 17px;
    margin-top: -2px;
    background-color: #bdbdbd;
    margin-left: 7px;
    border-color: transparent;
    border: 0px;

    -webkit-box-sizing: none;
    -moz-box-sizing: none;
    box-sizing: none;
}

.react-toggle-track {
    background-color: #e2e2e2;
    max-width: 35px;
    height: 75%;
    margin-top: 2px;
    margin-left: 9px;
}

.react-toggle--checked .react-toggle-track {
    background-color: #79c6f8;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #e2e2e2;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #79c6f8;
}

.react-toggle-track-check {
    left: 5px;
}

.react-toggle-track-x {
    right: 5px;
}

.react-toggle--checked .react-toggle-thumb {
    left: 22px;
    background-color: #0097fb;
    border: 0px;
}

.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
