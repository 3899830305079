.toolbar {
    font-family: 'Cera GR Medium';
    font-size: 13px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border-bottom: 1px solid #e2e2e2;

    .toolbar-chipWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 !important;
        &:hover {
            background-color: white !important;
        }

        > * {
            margin-right: 10px;
            opacity: 1;
        }
    }

    .toolbar-left,
    .toolbar-right {
        display: flex;
        align-items: stretch;

        > * {
            padding: 0 10px;
            text-transform: uppercase;

            &:not(.MuiBox-root):hover {
                background-color: rgba(0, 0, 0, 0.05);
                color: rgb(0, 162, 216);

                &.toolbar-button,
                .toolbar-button {
                    img,
                    i {
                        filter: invert(47%) sepia(38%) saturate(4639%)
                            hue-rotate(189deg) brightness(104%) contrast(91%);
                    }
                }
            }
        }
    }

    .toolbar-left {
        > * {
            border-right: 1px solid #e2e2e2;
        }
    }

    .toolbar-right {
        > * {
            &:not(:last-child) {
                border-right: 1px solid #e2e2e2;
            }
        }

        .menu {
            left: unset !important;
            right: 0;
            fill: #fff;
            filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))
                drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15));
        }
    }

    .dropdown {
        display: flex !important;
        align-items: center;

        > * {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
        &.active {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &.icon {
            display: none !important;
        }
    }

    .menu {
        .item {
            display: flex !important;
            align-items: center;

            > * {
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    }

    .toolbar-button {
        position: relative;
        min-width: 50px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        &.DataButton {
            min-width: 75px !important;
        }

        > * {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }

        .icons {
            margin-right: 7px;
        }
    }

    .exportButton {
        height: 24px;
        align-self: center;
        padding: 0 !important;
    }

    .icon {
        display: block;
        opacity: 0.7;
    }

    .plus-icon {
        height: 21px;
        width: 21px;
        background-image: url('../icons/PlusWhite.svg');
        background-position: center;
    }

    .single-screen-icon {
        height: 19px;
        width: 19.5px;
        vertical-align: sub;
        background-repeat: round;
        background-image: url('../icons/SingleScreenIcon.svg');
    }

    .multi-screen-icon {
        height: 20px;
        width: 18.4px;
        vertical-align: sub;
        background-repeat: round;
        background-position-y: -10%;
        background-image: url('../icons/MultiScreenIcon.svg');
    }

    .side-bar-icon {
        height: 17px;
        width: 20px;
        vertical-align: sub;
        background-position-x: 4px;
        background-repeat: no-repeat;
        background-image: url('../icons/SideBarIcon.svg');
    }

    .row-height-icon:before {
        font-weight: 900;
        font-family: sans-serif;
        content: 'Aa';
    }

    .ui.dropdown {
        .item {
            &.active,
            &.selected {
                font-weight: normal !important;

                &:not(:hover) {
                    background: none !important;
                }
            }
        }
    }

    .moreButtons-toggle-label {
        color: #000;
        font-family: Cera GR Medium !important;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .moreButtons-toggle-sub-label {
        color: #404040;
        font-family: Roboto !important;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 5px;
    }

    .reportsButton {
        .reportsButton-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            > *:first-child {
                padding-right: 10px;
            }
        }
    }

    .moreButton-shortcuts-overlay {
        border-top: 1px solid #ddd;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0px;
        z-index: 1;
    }

    .moreButton-shortcuts {
        position: relative;
        user-select: none;
        padding-top: 10px;
        padding-bottom: 10px;

        .moreButton-shortcuts-list {
            width: 350px;
            border-radius: 3px;
            box-shadow: var(--box-shadow);
            background: rgb(247, 247, 247);
            top: 32px;
            right: -25px;
            padding: 10px;
            position: absolute;
            font-weight: normal;
            border: 1px solid #ddd;
            cursor: default;

            > * {
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .moreButtons-switch {
        margin-bottom: 15px;
        justify-content: flex-end;
    }

    .grid-setting-label {
        display: flex;
        align-items: center;
    }

    .grid-settings-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-right: 10px;
    }

    .visible.menu.transition {
        margin-top: 4px;
        margin-right: 8px;
    }
}

// div.toolbar {
//   width: 100%;
//   flex: 0 0 45px;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   align-items: flex-start;
//   border-top: 1px solid rgb(212, 212, 213);
//   border-bottom: 1px solid rgb(212, 212, 213);
// }

// div.toolbar>div {
//   height: 100%;
// }

// div.toolbar div.NewOrderButton,
// div.toolbar div.NewFixtureButton {
//   padding-left: 25px;
//   padding-right: 18px;
// }

// div.toolbar .ui.dropdown .menu .active.item {
//   font-weight: unset;
// }

// div.toolbar>div.rightMenu {
//   position: absolute;
//   height: 45px;
//   right: 0;
//   direction: ltr;
//   display: flex;
//   justify-content: flex-end;
// }

// div.toolbar>div.rightMenu>div.layoutButton div.item {
//   padding: 0.78571429rem 0.8rem !important;
// }

// div.toolbar-button {
//   cursor: pointer;
//   font-family: 'CeraGR';
//   font-size: 13px;
//   padding-top: 11px;
//   padding-right: 10px;
//   display: flex;
//   height: 100%;
//   text-transform: uppercase;
// }

// div.toolbar>div.rightMenu div.menu,
// ul.MuiList-root div {
//   font-family: 'CeraGR';
// }

// div.toolbar-button:hover {
//   font-weight: bold;
//   color: #2196f3;
// }

// div.toolbar-button:hover>i,
// div.syncButton:hover>img,
// div.clearFilterButton:hover>img,
// div.restoreFilterButton:hover>img {
//   filter: invert(47%) sepia(38%) saturate(4639%) hue-rotate(189deg) brightness(104%) contrast(91%);
// }

// div.rightMenu div.toolbar-button {
//   display: block;
//   text-align: center;
//   padding-left: 21px;
//   padding-right: 21px;
//   border-right: 0;
//   border-left: 1px solid #d4d4d5;
// }

// div.rightMenu div.syncButton,
// div.rightMenu div.clearFilterButton,
// div.rightMenu div.restoreFilterButton {
//   padding: 10px;
// }

// div.rightMenu div.syncButton,
// div.rightMenu div.clearFilterButton,
// div.rightMenu div.restoreFilterButton {
//   cursor: pointer;
// }

// div.rightMenu a {
//   color: unset;
// }

// div.rightMenu .toolbar-button:hover {
//   background-color: rgba(0, 0, 0, 0.03);
//   font-weight: unset;
//   color: unset;
// }

// div.rightMenu .toolbar-button.active:not(:hover) {
//   background-color: rgba(0, 0, 0, 0.06);
//   font-weight: unset;
//   color: unset;
// }

// div.toolbar-button>.icons {
//   margin-right: 7px;
//   opacity: 0.7;
// }

// div.toolbar i.plus-icon {
//   height: 21px;
//   width: 21px;
//   background-image: svg('src/components/icons/PlusWhite.svg');
//   background-position: center;
// }

// div.toolbar button.exportButton {
//   height: 28px;
//   margin: 7px;
//   font-family: 'CeraGR';
//   font-weight: normal;
//   font-size: 13px;
//   padding-top: 0.58571429em !important;
// }

// div.toolbar i.sync-icon {
//   height: 22px;
//   width: 16px;
//   background-image: svg('src/components/icons/Sync.svg');
// }

// div.toolbar i.single-screen-icon {
//   height: 19px;
//   width: 19.5px;
//   vertical-align: sub;
//   background-repeat: round;
//   background-image: svg('src/components/icons/SingleScreenIcon.svg');
// }

// div.toolbar i.multi-screen-icon {
//   height: 20px;
//   width: 18.4px;
//   vertical-align: sub;
//   background-repeat: round;
//   background-position-y: -10%;
//   background-image: svg('src/components/icons/MultiScreenIcon.svg');
// }

// div.toolbar i.side-bar-icon {
//   height: 17px;
//   width: 20px;
//   vertical-align: sub;
//   background-position-x: 4px;
//   background-repeat: no-repeat;
//   background-image: svg('src/components/icons/SideBarIcon.svg');
// }

// div.toolbar i.row-height-icon:before {
//   font-weight: 900;
//   font-family: sans-serif;
//   content: 'Aa';
// }

// div.toolbar .dropdown.icon {
//   display: none;
// }

// div.toolbar>div.rightMenu div.layoutButton div.menu {
//   position: absolute;
//   top: 36px;
//   left: -150px;
// }

// div.toolbar>div.rightMenu div.reportsButton div.menu {
//   position: absolute;
//   top: 36px;
//   left: -80px;
// }

// div.toolbar ul.switchgroup-dropdown {
//   top: 25px;
//   left: unset;
//   cursor: pointer;
//   font-family: 'CeraGR';
//   font-size: 13px;
// }

// div.toolbar>div.menu-item {
//   padding: 11px 10px 10px 10px;
//   cursor: pointer;
//   font-family: 'CeraGR';
//   font-size: 13px;
// }

// div.toolbar div.menu.transition {
//   font-family: 'CeraGR';
//   font-size: 13px;
// }

// Need to customize the dropdown menu a bit (Data) to look like the other menu items (Activity, Layout)
// as a different component is used
.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiListItem-button:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
    z-index: 13;
}

.MuiPaper-root {
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
}

.MuiMenuItem-root {
    font-family: 'Cera GR Medium' !important;
    min-width: 200px !important;
}

//Some tooltip styles
.rc-tooltip {
    z-index: 9999;
}

.rc-tooltip-inner {
    color: black;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.17);
    background-color: white;
}

.rc-tooltip-arrow {
    border-top-color: white !important;
    box-shadow: unset;
}

.more-button-container {
    display: flex !important;
    font-family: 'Cera GR Medium';
}

.new-theme-enabled {
    display: flex !important;
    font-family: 'Noto Sans';
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    .new-ui-moreButtons-toggle-label,
    .grid-setting-label,
    .moreButton-shortcuts-overlay,
    .moreButton-shortcuts,
    .moreButton-shortcuts-list {
        color: #000;
        font-family: 'Noto Sans' !important;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 600 !important;
        line-height: normal;
    }

    .new-ui-moreButtons-toggle-sub-label {
        color: #404040;
        font-family: 'Noto Sans' !important;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .density-container {
        #header {
            margin-bottom: 12px;
            color: #000;
            font-family: 'Noto Sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .radio-row {
            display: flex !important;
            align-items: center;
            color: #404040;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Noto Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 143%;
            letter-spacing: 0.17px;
            height: 38px;
            padding: 0px 8px;
            margin-left: 10px;
        }

        input[type='radio'] {
            cursor: pointer;
            width: 15px;
            height: 15px;
            border: 2px solid #747474;
            border-radius: 50%;
            outline: none;
            opacity: 0.6;
        }

        input[type='radio']:hover:before {
            box-shadow: 0px 0px 0px 8px rgba(80, 80, 200, 0.2);
            border-radius: 50%;
            opacity: 1;
        }

        input[type='radio']:before {
            content: '';
            display: block;
            width: 60%;
            height: 60%;
            margin: 20% auto;
            border-radius: 50%;
            transition: 0.3s;
        }

        input[type='radio']:checked:before {
            background: #000;
        }

        .radio-row label {
            display: inline-flex;
            padding: 0px 8px;
            cursor: pointer;
            align-items: center;
        }

        .svg-container {
            display: flex;
            align-items: center;
            width: 100%;
            margin-left: 30px;
        }

        .svg-content-compact,
        .svg-content-standard {
            width: 84px;
            height: 25px;
            flex-shrink: 0;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        .svg-content-compact {
            background: url('../icons/density-compact.svg') no-repeat;
        }

        .svg-content-standard {
            background: url('../icons/density-standard.svg') no-repeat;
        }
    }
}
