.user-menu {
    position: 'absolute';
    z-index: 999;
    background-color: 'white';
    transition: 'opacity .1s ease';
    border-radius: '0 0 .28571429rem .28571429rem';
    box-shadow: '0 2px 3px 0 rgba(34;36;38;.15)';
    width: '30em';
    max-height: '40rem';
    overflow-y: 'auto';
    overflow-x: 'hidden';
    border-color: '#96c8da';
    border-top-width: '0';
    border-right-width: 1;
    border-bottom-width: 1;
    border-left-width: 1;
    border-style: 'solid';
}

.user-menu .user-menu-item {
    position: 'relative';
    cursor: 'pointer';
    display: 'block';
    border: 'none';
    height: 'auto';
    text-align: 'left';
    border-top: 'none';
    line-height: '1em';
    color: 'rgba(0;0;0;.87)';
    font-size: '1rem';
    text-transform: 'none';
    font-weight: '400';
    box-shadow: 'none';
    padding: '.8rem 1.1rem';
    white-space: 'normal';
    word-wrap: 'normal';
}
