ul.switchgroup-dropdown {
    margin: 0;
    top: 100%;
    left: 0;
    position: absolute;
    z-index: 999;
    background-color: #ffffff;
    list-style: none;
    padding: 0;
    border: 1px solid rgb(212, 212, 213);
    color: black !important;
}

ul.switchgroup-dropdown li {
    height: 40px;
    padding: 10px;
    white-space: nowrap;
}

ul.switchgroup-dropdown li:hover {
    background-color: lightgray;
    cursor: pointer;
}
