div#activity {
    font-family: 'Roboto', 'Cera GR Light', sans-serif;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 2;
}

div#activity div#activity-menu .a:first-child {
    border-radius: 5px 0 0 0;
}

/* Compact */

div#activity.activity-compact {
    width: 1050px;
}

div#activity.activity-compact.single {
    top: 210px;
    height: 480px;
}

div#activity.activity-compact.multi-fixtures {
    top: 528px;
    height: 425px;
}

div#activity.activity-compact.multi-orders {
    top: 158px;
    height: 480px;
}

div#activity.activity-compact div.activity-container {
    width: 100%;
    height: 100%;
    border-radius: 0 0 0 5px;
}

div#activity.activity-compact div.activity-content {
    background-color: #fff;
    float: right;
    height: 100%;
    width: 1000px;
    box-shadow: -1px 2px 3px 2px #ccc;
    border-radius: 0 0 0 5px;
    padding: 10px;
    font-size: smaller;
    overflow-y: auto;
}

div#activity.activity-compact div#activity-menu {
    background: #fff;
    border-radius: 5px 0 0 5px;
    border-bottom: 1px solid #eee;
    box-shadow: -1px 0px 3px 2px #ccc;
}

div#activity.activity-compact div#activity-menu div.mainmenu div.menu-slide-in {
    cursor: pointer;
    height: 55px;
    width: 50px;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjhweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgOCAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4NCiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjUgKDY3NDY5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4NCiAgICA8dGl0bGU+TWFzazwvdGl0bGU+DQogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+DQogICAgPGRlZnM+DQogICAgICAgIDxwb2x5Z29uIGlkPSJwYXRoLTEiIHBvaW50cz0iMTYuMyA4LjMgMTEuNyAxMi45IDcuMSA4LjMgNS43IDkuNyAxMS43IDE1LjcgMTcuNyA5LjciPjwvcG9seWdvbj4NCiAgICA8L2RlZnM+DQogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPg0KICAgICAgICA8ZyBpZD0iSWNvbnMvX0NoZXZyb25zL2NoZXZyb24tZG93biIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTguMDAwMDAwLCAtNi4wMDAwMDApIj4NCiAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4NCiAgICAgICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPg0KICAgICAgICAgICAgPC9tYXNrPg0KICAgICAgICAgICAgPHVzZSBpZD0iTWFzayIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJub256ZXJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS43MDAwMDAsIDEyLjAwMDAwMCkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTEuNzAwMDAwLCAtMTIuMDAwMDAwKSAiIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPg0KICAgICAgICA8L2c+DQogICAgPC9nPg0KPC9zdmc+');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

div#activity-menu div.mainmenu span.menu-item {
    width: 200px;
    height: 54px;
}

div#activity.activity-compact div.menu-toggle-format {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjE0cHgiIGhlaWdodD0iMTRweCIgdmlld0JveD0iMCAwIDE0IDE0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPg0KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTIuNSAoNjc0NjkpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPg0KICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+DQogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+DQogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgICAgIDxnIGlkPSJGaXh0dXJlLWNvdW50LWV4cGFuZGVkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTI3MC4wMDAwMDAsIC02MzQuMDAwMDAwKSIgZmlsbD0iIzAwMDAwMCI+DQogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtNC4wMDAwMDApIj4NCiAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAtOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTI0LjAwMDAwMCwgNjIzLjAwMDAwMCkiPg0KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNzQ4LDI0IEw3NDYsMjQgTDc0NiwyOSBMNzUxLDI5IEw3NTEsMjcgTDc0OCwyNyBMNzQ4LDI0IEw3NDgsMjQgWiBNNzQ2LDIwIEw3NDgsMjAgTDc0OCwxNyBMNzUxLDE3IEw3NTEsMTUgTDc0NiwxNSBMNzQ2LDIwIEw3NDYsMjAgWiBNNzU4LDI3IEw3NTUsMjcgTDc1NSwyOSBMNzYwLDI5IEw3NjAsMjQgTDc1OCwyNCBMNzU4LDI3IEw3NTgsMjcgWiBNNzU1LDE1IEw3NTUsMTcgTDc1OCwxNyBMNzU4LDIwIEw3NjAsMjAgTDc2MCwxNSBMNzU1LDE1IEw3NTUsMTUgWiIgaWQ9IlNoYXBlIj48L3BhdGg+DQogICAgICAgICAgICAgICAgPC9nPg0KICAgICAgICAgICAgPC9nPg0KICAgICAgICA8L2c+DQogICAgPC9nPg0KPC9zdmc+');
    background-repeat: no-repeat;
    height: 14px;
    width: 14px;
}

div#activity.activity-expanded div.menu-toggle-format {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjE0cHgiIGhlaWdodD0iMTRweCIgdmlld0JveD0iMCAwIDE0IDE0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPg0KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTIuNSAoNjc0NjkpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPg0KICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+DQogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+DQogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgICAgIDxnIGlkPSJGaXh0dXJlLWNvdW50LWV4cGFuZGVkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTMxNy4wMDAwMDAsIC02MzQuMDAwMDAwKSIgZmlsbD0iIzAwMDAwMCI+DQogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtNC4wMDAwMDApIj4NCiAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAtOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTI0LjAwMDAwMCwgNjIzLjAwMDAwMCkiPg0KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNzkzLDI2IEw3OTYsMjYgTDc5NiwyOSBMNzk4LDI5IEw3OTgsMjQgTDc5MywyNCBMNzkzLDI2IEw3OTMsMjYgWiBNNzk2LDE4IEw3OTMsMTggTDc5MywyMCBMNzk4LDIwIEw3OTgsMTUgTDc5NiwxNSBMNzk2LDE4IEw3OTYsMTggWiBNODAyLDI5IEw4MDQsMjkgTDgwNCwyNiBMODA3LDI2IEw4MDcsMjQgTDgwMiwyNCBMODAyLDI5IEw4MDIsMjkgWiBNODA0LDE4IEw4MDQsMTUgTDgwMiwxNSBMODAyLDIwIEw4MDcsMjAgTDgwNywxOCBMODA0LDE4IEw4MDQsMTggWiIgaWQ9IlNoYXBlIj48L3BhdGg+DQogICAgICAgICAgICAgICAgPC9nPg0KICAgICAgICAgICAgPC9nPg0KICAgICAgICA8L2c+DQogICAgPC9nPg0KPC9zdmc+');
    background-repeat: no-repeat;
    height: 14px;
    width: 14px;
}

div#activity.activity-compact
    div#activity-menu
    div.mainmenu
    div.menu-toggle-format {
    cursor: pointer;
    margin: 22px 20px 10px 10px;
    margin-left: auto;
}

div#activity.activity-compact div#activity-menu div.mainmenu div.menu-close {
    display: none;
}

div#activity.activity-compact div.fixture-count-placeholder {
    height: 100%;
    width: 100%;
    border-radius: 0 0 0 5px;
}

/* Expanded */

div#activity.activity-expanded {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
}

div#activity.activity-expanded div.activity-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 80%;
    width: 75%;
}

div#activity.activity-expanded div#activity-menu {
    background: #fff;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #eee;
}

div#activity.activity-expanded div.menu-slide-in {
    display: none;
}

div#activity.activity-expanded div.menu-toggle-format {
    cursor: pointer;
    margin: 22px 20px 0 auto;
}

div#activity.activity-expanded div.menu-close {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin: 22px 15px 0 5px;
}

div#activity.activity-expanded div.activity-content {
    height: 100%;
    width: 100%;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    padding: 10px;
    overflow-y: auto;
}

div#activity.activity-expanded div.fixture-count-placeholder {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

div#activity iframe {
    border: 0;
}

/* Slide Animations */

.activity-enter {
    transform: translateX(100%);
}

.activity-enter.activity-enter-active {
    transform: translateX(0%);
    transition: 400ms ease-in-out;
}

.activity-exit {
    transform: translateX(0%);
}

.activity-exit.activity-exit-active {
    transform: translateX(100%);
    transition: 400ms ease-in-out;
}

div#activity .external.icon {
    height: unset;
    padding-left: 5px;
}
