.slidebtn {
    --red-1: #ff1744;
    position: relative;
    display: flex;
    width: fit-content;

    .slidebtn-overlay {
        z-index: 1;
        // background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &.open {
        color: white;

        > * {
            z-index: 1;
        }

        .slidebtn-content {
            color: white;
            background-color: var(--red-1);
        }
    }

    .slidebtn-content {
        position: relative;
        padding: 3px;
        border-radius: 3px;

        &:hover {
            background-color: var(--red-1);
            color: white;
        }

        transition: all 0.35s ease-out;
    }

    .slidebtn-icon {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .slidebtn-slide-wrapper {
        position: relative;
        width: 0;
        margin-right: -3px;
    }

    .slidebtn-slide {
        position: relative;
        height: 100%;
        display: flex;
        transform: translateX(-100%);
        overflow: hidden;
        align-items: center;
        background: var(--red-1);
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        cursor: pointer;
        background: rgb(255, 116, 143);

        &:hover {
            background: var(--red-1);
        }

        transition: all 0.3s ease-out;
    }

    .slidebtn-slide-content {
        padding: 0 10px;
        text-transform: uppercase;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        color: white;
    }
}
