.grid .ag-popup-editor input.amount-input {
    border: 1px solid hsl(0, 0%, 80%) !important;
    border-radius: 4px !important;
    margin-top: 0em !important;
    width: 135px !important;
    height: 38px !important;
}

.grid .ag-popup-editor input.amount-input:disabled {
    background: hsl(0, 0%, 95%) !important;
}

input.amount-input:focus-visible {
    box-shadow: 0 0 0 1px #2684ff;
    margin-top: 0.3em;
}

.grid .ag-popup-editor input.quantity-amount-input {
    @extend input.amount-input;
    width: 200px !important;
}

.grid .ag-popup-editor input.duration-amount-input {
    @extend input.amount-input;
    width: 90px !important;
}

.inline {
    float: left;
    margin: 5px;
    margin-right: 0;
    margin-bottom: 10px;
}

.first-inline-item {
    @extend .inline;
    margin-left: 11px;
}

.inline-title {
    margin-left: 5px;
    font-weight: bold;
}

.vertical-divider {
    border-left: 2px solid lightgray;
    height: 60px;
    position: relative;
}

.capture-button {
    margin-right: 10px;
}

.icon-inside {
    position: relative;
}

.icon-inside i {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
    color: gray;
}

div.list__input-container > input.list__input {
    padding: 0px !important;
}
