input.dwt-filter-input {
    border: 1px solid hsl(0, 0%, 80%) !important;
    border-radius: 4px !important;
    margin: 10px;
    height: 25px;
    width: 120px;
    text-align: center;
    display: inline-block;
}

input.dwt-filter-input:focus {
    box-shadow: 0 0 0 1px #2684ff !important;
    outline: none;
}
