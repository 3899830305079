.layoutManager {
    position: fixed;
    height: 80%;
    right: 0;
    bottom: 0;
    z-index: 2;
    box-shadow: -1px 2px 3px 2px #ccc;
    background: white;

    .layoutManager-actions {
        > * {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }

    .layoutManager-pencil {
        margin-left: 10px;
        visibility: hidden;
    }

    .layoutManager-pencil,
    .layoutManager-actions {
        > * {
            cursor: pointer;
        }
        img {
            &:hover {
                filter: invert(47%) sepia(38%) saturate(4639%)
                    hue-rotate(189deg) brightness(104%) contrast(91%);
            }
        }
    }

    .layoutManager-content {
        height: calc(100% - 55px);
        border-top: 1px solid rgba(34, 36, 38, 0.15);
        overflow: auto;
        .ui.table {
            margin-top: -1px;
            border-radius: 0;
            border-left: 0;
            border-right: 0;
            font-size: 14px;

            th {
                border-radius: 0 !important;
            }

            tr:hover {
                background-color: rgba(0, 0, 0, 0.05);

                .layoutManager-pencil {
                    visibility: visible;
                }
            }
        }
    }

    .layoutManager-table-cell {
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
            width: 224px;
        }
    }

    .layoutManager-layout-name {
        max-width: 200px;
        max-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .layoutManager-topbar {
        display: flex;
        align-items: center;
        height: 55px;
        margin-left: -55px;
        border-radius: 5px 0 0 5px;
        box-shadow: 0px 0px 3px 3px #ccc;
        background-color: white;
    }

    .layoutManager-topbar-title {
        font-size: 15px;
        font-weight: bold;
    }

    .layoutManager-topbar-chevron {
        cursor: pointer;
        width: 55px;
        height: 55px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjhweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgOCAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4NCiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjUgKDY3NDY5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4NCiAgICA8dGl0bGU+TWFzazwvdGl0bGU+DQogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+DQogICAgPGRlZnM+DQogICAgICAgIDxwb2x5Z29uIGlkPSJwYXRoLTEiIHBvaW50cz0iMTYuMyA4LjMgMTEuNyAxMi45IDcuMSA4LjMgNS43IDkuNyAxMS43IDE1LjcgMTcuNyA5LjciPjwvcG9seWdvbj4NCiAgICA8L2RlZnM+DQogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPg0KICAgICAgICA8ZyBpZD0iSWNvbnMvX0NoZXZyb25zL2NoZXZyb24tZG93biIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTguMDAwMDAwLCAtNi4wMDAwMDApIj4NCiAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4NCiAgICAgICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPg0KICAgICAgICAgICAgPC9tYXNrPg0KICAgICAgICAgICAgPHVzZSBpZD0iTWFzayIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJub256ZXJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS43MDAwMDAsIDEyLjAwMDAwMCkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTEuNzAwMDAwLCAtMTIuMDAwMDAwKSAiIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPg0KICAgICAgICA8L2c+DQogICAgPC9nPg0KPC9zdmc+');
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
    }

    input {
    }
}

// $font-weight-regular: 400;
// $font-weight-medium: 500;
// $font-weight-bold: 700;

// div#layoutManager {
//   //font-family: 'Roboto', 'Cera GR Light', sans-serif;
//   position: fixed;
//   right: 0;
//   bottom: 0;
//   z-index: 2;
// }

// div#layoutManager div#layoutManager-menu .a:first-child {
//   border-radius: 5px 0 0 0;
// }

// div#layoutManager div.menu-title {
//   //font-family: 'Roboto', 'Cera GR Light', sans-serif;
//   font-weight: bold;
//   font-size: large;
//   margin-top: 20px;
// }

// /* Compact */

// div#layoutManager.layoutManager-compact {
//   width: 700px;
//   top: 132px;
//   height: 480px;
//   z-index: 1000;
// }

// div#layoutManager.layoutManager-compact div.layoutManager-container {
//   width: 100%;
//   height: 100%;
//   border-radius: 0 0 0 5px;
// }

// div#layoutManager.layoutManager-compact div.layoutManager-content {
//   background-color: #fff;
//   float: right;
//   height: 100%;
//   width: 640px;
//   box-shadow: -1px 2px 3px 2px #ccc;
//   border-radius: 0 0 0 5px;
//   padding: 10px;
//   overflow-y: auto;
// }

// div#layoutManager.layoutManager-compact div#layoutManager-menu {
//   background: #fff;
//   border-radius: 5px 0 0 5px;
//   border-bottom: 1px solid #eee;
//   box-shadow: -1px 0px 3px 2px #ccc;
// }

// div#layoutManager.layoutManager-compact
//   div#layoutManager-menu
//   div.mainmenu
//   div.menu-slide-in {
//   cursor: pointer;
//   height: 55px;
//   width: 50px;
//   background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjhweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgOCAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4NCiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjUgKDY3NDY5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4NCiAgICA8dGl0bGU+TWFzazwvdGl0bGU+DQogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+DQogICAgPGRlZnM+DQogICAgICAgIDxwb2x5Z29uIGlkPSJwYXRoLTEiIHBvaW50cz0iMTYuMyA4LjMgMTEuNyAxMi45IDcuMSA4LjMgNS43IDkuNyAxMS43IDE1LjcgMTcuNyA5LjciPjwvcG9seWdvbj4NCiAgICA8L2RlZnM+DQogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPg0KICAgICAgICA8ZyBpZD0iSWNvbnMvX0NoZXZyb25zL2NoZXZyb24tZG93biIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTguMDAwMDAwLCAtNi4wMDAwMDApIj4NCiAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4NCiAgICAgICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPg0KICAgICAgICAgICAgPC9tYXNrPg0KICAgICAgICAgICAgPHVzZSBpZD0iTWFzayIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJub256ZXJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS43MDAwMDAsIDEyLjAwMDAwMCkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTEuNzAwMDAwLCAtMTIuMDAwMDAwKSAiIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPg0KICAgICAgICA8L2c+DQogICAgPC9nPg0KPC9zdmc+');
//   background-repeat: no-repeat;
//   background-position-x: center;
//   background-position-y: center;
// }

// div#layoutManager.layoutManager-compact
//   div#layoutManager-menu
//   div.mainmenu
//   div.menu-close {
//   display: none;
// }

// div#layoutManager.layoutManager-compact table {
//   width: 90%;
//   text-align: initial;
// }

// div#layoutManager.layoutManager-compact table th {
//   background-color: #e0e0e0;
// }

// div#layoutManager.layoutManager-compact table td,
// div#layoutManager.layoutManager-compact table th {
//   padding: 4px;
//   text-align: initial;
// }

// .layoutManager-content > div > table > tbody > tr > td > div {
//   vertical-align: middle;
//   display: flex;
// }

// .layoutManager-content table tr td div.layout-manager-pencil {
//   float: right;
//   width: 25px;
//   z-index: 1000;
//   cursor: pointer;
// }

// .confirm-delete-button {
//   background-color: red;
//   cursor: pointer;
//   border: none;
//   padding: 2px;
//   outline: none;
//   width: 100%;
//   text-align: right;
//   color: white;
// }

// .layoutManager-content table tr td.delete-button {
//   float: right;
//   text-align: right;
//   padding-right: 10px;
// }
