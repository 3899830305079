#location-search-results-table {
    table-layout: fixed;
    width: 100%;
}

#location-search-results-table td {
    padding-left: 8px;
    width: 100px;
    text-align: left;
    font-weight: bold;
    padding-right: 0px;

    &:not(:first-child) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &:first-child {
        padding-left: 0px !important;
    }

    &:nth-last-child(-n + 2) {
        width: 77px; // UX decision - should be equals to size of MMMMMM
    }
}

.location-aliases {
    border: none !important;
    font-weight: normal !important;
    padding-top: 0 !important;
    text-overflow: clip !important;
    white-space: normal !important;
}

.location-aliases-stub {
    border: none !important;
    width: 10px !important;
    min-width: 0 !important;
    padding-top: 0 !important;
}
