@import './clarksons/clarksons.scss';

.default-theme {
    --grey-1: rgba(0, 0, 0, 0.09);
    --grey-2: rgba(82, 59, 59, 0.4);
    --grey-3: #87919b;
    --grey-4: #e2e2e2;
    --grey-5: #444;
    --grey-6: #555;
    --blue-1: rgb(33, 150, 243);
    --blue-2: rgba(229, 242, 255);
    --blue-3: rgba(33, 150, 243, 0.4);
    --black-1: #000;
    --black-2: #333;
    --red-1: #ff1744;
    --red-2: #7a0000;
    --white-1: #fff;
    --orange-1: #ed6f00;
    --pink-1: #ae2f5a;
    --pink-2: #f7cdd3;
    --pink-3: #ee95a2;
    --pink-4: #dd8aa5;
    --pink-5: #F6BFBF;
    --green-1: #00a65a;
    --group-grey-0: #808080;
    --group-grey-1: #a6a6a6;
    --group-grey-2: #d3d3d3;
    --primary-color: var(--blue-1);
    --secondary-color: var(--blue-1);
    --info-color: var(--blue-1);
    --error-color: var(--red-1);
    --warning-color: var(--yellow-1);
    --box-border-color: var(--grey-4);
    --box-border-focus-color: var(--primary-color);
    --box-border: 1px solid var(--box-border-color);
    --box-focus-border: 1px solid var(--box-border-focus-color);
    --box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    --box-background-color: var(--white-1);
    --box-active-bg-color: var(--blue-3);
    --box-bg-color-transition: background-color 0.2s ease-in-out;
    --box-border-color-transition: border-color 0.15s ease-in-out;
    --box-padding-small: 10px;
    --box-padding-medium: 15px;
    --box-padding-large: 20px;
    --box-border-radius-small: 2px;
    --box-border-radius-medium: 3px;
    --list-item-active-bg-color: var(--box-active-bg-color);
    --list-item-bg-color-transition: var(--box-bg-color-transition);
    --list-item-border: var(--box-border);
    --input-border-color: var(--box-border-color);
    --input-border-color-transition: var(--box-border-color-transition);
    --input-border-focus-color: var(--box-border-focus-color);
    --input-border: var(--box-border);
    --input-border-focus: var(--box-focus-border);
    --input-border-radius: var(--box-border-radius-small);
    --input-color: var(--black-2);
    --input-height: 40px;
    --input-padding-horizontal: 7px;
    --input-padding: 0 10px;
    --icon-dimensions: 24px;

    --field-label-font-size: 12px;
    --field-label-color: var(--grey-3);
}
