@keyframes stripe-in-out {
    0% {
        transform: skewX(-30deg) scale(0.6);
        opacity: 0;
    }
    20% {
        transform: skewX(-30deg) scale(1);
        opacity: 0.8;
    }
    45% {
        transform: skewX(-30deg) scale(0.6);
        opacity: 0;
    }
    100% {
        transform: skewX(-30deg) scale(0.6);
        opacity: 0;
    }
}

.striped-loader {
    display: flex;
    flex-direction: row;
    margin: auto;
}

.striped-loader.x-small {
    height: 12px;
    width: 27px;
}
.striped-loader.small {
    height: 16px;
    width: 36px;
}
.striped-loader.medium {
    height: 24px;
    width: 54px;
}
.striped-loader.large {
    height: 32px;
    width: 71px;
}
.striped-loader.x-large {
    height: 40px;
    width: 89px;
}

.stripe {
    height: 100%;
    width: 20%;
    padding: 0 4%;
    box-sizing: border-box;
}

.stripe-inner {
    background: white;
    height: 100%;
    width: 100%;
    opacity: 0;
    animation-name: stripe-in-out;
    animation-duration: 1.44s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.stripe:nth-child(1) .stripe-inner {
    animation-delay: 0s;
}

.stripe:nth-child(2) .stripe-inner {
    animation-delay: 0.2s;
}

.stripe:nth-child(3) .stripe-inner {
    animation-delay: 0.4s;
}

.stripe:nth-child(4) .stripe-inner {
    animation-delay: 0.6s;
}

.stripe:nth-child(5) .stripe-inner {
    animation-delay: 0.8s;
}

.dark-stripe .stripe-inner,
.light-background .stripe-inner {
    background: #112439;
}

.dark-background .stripe-inner,
.light-stripe .stripe-inner {
    background: white;
}
