@font-face {
    font-family: 'Cera GR Bold';
    src: url('./scss/fonts/Cera-GR-Bold/d191325cfe4f374d6f43147fd57ab2d4.eot');
    src: url('./scss/fonts/Cera-GR-Bold/d191325cfe4f374d6f43147fd57ab2d4.eot?#iefix')
            format('embedded-opentype'),
        url('./scss/fonts/Cera-GR-Bold/d191325cfe4f374d6f43147fd57ab2d4.woff2')
            format('woff2'),
        url('./scss/fonts/Cera-GR-Bold/d191325cfe4f374d6f43147fd57ab2d4.woff')
            format('woff'),
        url('./scss/fonts/Cera-GR-Bold/d191325cfe4f374d6f43147fd57ab2d4.ttf')
            format('truetype'),
        url('./scss/fonts/Cera-GR-Bold/d191325cfe4f374d6f43147fd57ab2d4.svg#Cera GR Bold')
            format('svg');
}

@font-face {
    font-family: 'Cera GR Medium';
    src: url('./scss/fonts/Cera-GR-Medium/508fb4f19c40b7c6a170913ad9a5de34.eot');
    src: url('./scss/fonts/Cera-GR-Medium/508fb4f19c40b7c6a170913ad9a5de34.eot?#iefix')
            format('embedded-opentype'),
        url('./scss/fonts/Cera-GR-Medium/508fb4f19c40b7c6a170913ad9a5de34.woff2')
            format('woff2'),
        url('./scss/fonts/Cera-GR-Medium/508fb4f19c40b7c6a170913ad9a5de34.woff')
            format('woff'),
        url('./scss/fonts/Cera-GR-Medium/508fb4f19c40b7c6a170913ad9a5de34.ttf')
            format('truetype'),
        url('./scss/fonts/Cera-GR-Medium/508fb4f19c40b7c6a170913ad9a5de34.svg#Cera GR Medium')
            format('svg');
}

@font-face {
    font-family: 'Cera GR';
    src: url('./scss/fonts/Cera-GR/b2654d24a0afc19dabe02fba17411648.eot');
    src: url('./scss/fonts/Cera-GR/b2654d24a0afc19dabe02fba17411648.eot?#iefix')
            format('embedded-opentype'),
        url('./scss/fonts/Cera-GR/b2654d24a0afc19dabe02fba17411648.woff2')
            format('woff2'),
        url('./scss/fonts/Cera-GR/b2654d24a0afc19dabe02fba17411648.woff')
            format('woff'),
        url('./scss/fonts/Cera-GR/b2654d24a0afc19dabe02fba17411648.ttf')
            format('truetype'),
        url('./scss/fonts/Cera-GR/b2654d24a0afc19dabe02fba17411648.svg#Cera GR')
            format('svg');
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Cera GR', 'Roboto', 'Cera GR Light', sans-serif;
    text-rendering: geometricPrecision;
    color: var(--font-color-primary);
}

body ::-webkit-scrollbar-track {
    background: unset !important;
    border-radius: unset !important;
}

body ::-webkit-scrollbar {
    -webkit-appearance: unset !important;
    width: unset !important;
    height: unset !important;
}
