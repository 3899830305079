.modal {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);

    .modal-overlay {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .modal-content {
        z-index: 2;
        position: relative;
        padding: 20px;
        border-radius: 10px;
        background-color: #fff;

        .close {
            box-sizing: content-box;
            position: absolute;
            top: 5px;
            right: 5px;
            padding: 5px;
            border-radius: 50%;
            color: grey;
            cursor: pointer;
            transition: background-color 0.2s ease-out;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}
